import { ChangeEvent, DragEvent, useRef, useState } from 'react';

import styles from './AppFileInput.module.scss';
import classNames from 'classnames';
import { IconUpload } from '../../icons';
import isEmpty from 'is-empty-typed';

type Props = {
  onChange: (files: File[]) => void;
  accept: string;
  label: string;
  multiple?: boolean;
  className?: string;
  disabled?: boolean;
};

export function AppFileInput({
  onChange,
  accept,
  label,
  multiple = false,
  className,
  disabled,
}: Props) {
  const [isDragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    }
  };

  const handleDrop = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      setDragActive(false);
      const files = getFiles(e);
      !isEmpty(files) && areFileTypesValid(files) && onChange(files);
    }
  };

  const areFileTypesValid = (files: File[]): boolean =>
    files.every((file) => accept.includes(file.type));

  const getFiles = (e: DragEvent<HTMLDivElement>): File[] => Array.from(e.dataTransfer.files || []);

  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onChange(Array.from(e.target.files));
    }
    inputRef.current!.value = '';
  };

  const handleClick = () => {
    if (!disabled) {
      inputRef.current!.click();
    }
  };

  return (
    <div className={classNames(styles.appFileInput, className)} onDragEnter={handleDrag}>
      <input
        ref={inputRef}
        type='file'
        accept={accept}
        className={styles.input}
        multiple={multiple}
        onChange={handleChange}
        disabled={disabled}
      />
      <div
        className={classNames(styles.clickZone, {
          [styles.dragActive]: isDragActive,
          [styles.disabled]: disabled,
        })}
        onClick={handleClick}
      >
        <div className={styles.iconFileContainer}>
          <IconUpload className={styles.uploadIcon} />
        </div>
        <div className={styles.innerLabel}>
          <div className={styles.highlightedLabel}>Click to upload</div>
          <div>or drag and drop</div>
        </div>
        <div className={styles.label}>{label}</div>
      </div>
      {isDragActive && (
        <div
          className={styles.dragZone}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
}
