import { z } from 'zod';
import { userApi } from './userApi';
import { AppDispatch } from '../../store';

const username = z.string().regex(/^(?=.{5,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/, {
  message: 'Must be 5-20 characters long. Only letters and numbers allowed',
});
const email = z.string().email({ message: 'Invalid email' });
const fullName = z
  .string()
  .min(5, { message: 'Must be 5 or more characters long' })
  .max(20, { message: 'Must be 20 or fewer characters long' });
const bio = z
  .string()
  .min(10, { message: 'Must be 10 or more characters long' })
  .max(500, { message: 'Must be 500 or fewer characters long' });
const url = z.string().url();

export const profileSchema = z.object({
  username,
  email,
  fullName,
  bio,
  website: url,
  imdb: url,
  twitter: url,
  discord: url,
  instagram: url,
});

export const updateProfileSchema = z.object({
  username: username.or(z.literal('')),
  email: email.or(z.literal('')),
  fullName: fullName.or(z.literal('')),
  bio: bio.or(z.literal('')),
  website: url.or(z.literal('')),
  imdb: url.or(z.literal('')),
  twitter: url.or(z.literal('')),
  spotify: url.or(z.literal('')),
  facebook: url.or(z.literal('')),
  discord: url.or(z.literal('')),
  instagram: url.or(z.literal('')),
  avatarId: z.string().min(24).or(z.literal('')).nullable(),
  backgroundId: z.string().min(24).or(z.literal('')).nullable(),
});

export const getProfileAsyncSchema = (dispatch: AppDispatch) =>
  z.object({
    username: z.string().refine(
      async (username) => {
        return await dispatch(userApi.endpoints.checkUsername.initiate({ username })).unwrap();
      },
      {
        message: 'Username already exists',
      },
    ),
  });

export const creatorNameSchema = profileSchema.pick({
  username: true,
  fullName: true,
  email: true,
});

export const creatorBioSchema = profileSchema.pick({
  bio: true,
});

export const creatorSocialSchema = profileSchema.pick({
  website: true,
  imdb: true,
  twitter: true,
});
