import { OpenContextModal } from '@mantine/modals/lib/context';
import { IconMail } from '@tabler/icons-react';

export * from './GetNotifiedModal';

export function getGetNotifiedContext(innerProps: { symbol: string }): OpenContextModal<{
  symbol: string;
}> {
  return {
    title: 'Set a notification',
    innerProps,
  };
}
