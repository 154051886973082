import styles from './UserFanCollections.module.scss';
import { CollectionType, useFindUserFanCollectionsQuery } from '../../../../../modules/collection';
import { AppCollectionPreview, AppNoItems, AppSkeleton } from '../../../../../components';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../modules/auth';

type Props = {
  userId: string;
};

export function UserFanCollections({ userId }: Props) {
  const user = useSelector(selectUser);
  const isUserCollections = user && userId === user.id;
  const { data: collections = [], isLoading } = useFindUserFanCollectionsQuery({ userId });
  const skeletons = Array.from({ length: 4 }, (_, index) => index);

  return (
    <div className={styles.userFanCollections}>
      {isLoading ? (
        skeletons.map((place) => <AppSkeleton className={styles.item} key={place} />)
      ) : collections.length ? (
        collections.map((collection) => (
          <AppCollectionPreview
            className={styles.item}
            key={collection.id}
            collection={collection}
            posterAsBackground
          />
        ))
      ) : (
        <AppNoItems
          className={styles.noItems}
          type={CollectionType.Fan}
          title='No collections here yet'
          description={isUserCollections ? 'Create your first fan collection' : undefined}
        />
      )}
    </div>
  );
}
