import { IUser, Role, TokensDto } from "../modules/auth";
import isEmpty from "is-empty-typed";
import { utils } from "ethers";
import jwt from "jwt-decode";
import { LocalStorage } from "../enums";

export function isCreator(user: IUser | null): boolean {
  return hasAccess(user, Role.Creator);
}

export function isAdmin(user: IUser | null): boolean {
  return hasAccess(user, Role.Admin);
}

export function hasAccess(
  user: IUser | null,
  role: Role | Role[] = []
): boolean {
  const roles = ([] as Role[]).concat(role);

  return (
    isEmpty(roles) ||
    !!(user && user.roles.some((role) => roles.includes(role)))
  );
}

export function areAddressesSame(
  address1: string | undefined,
  address2: string | undefined
): boolean {
  if (!address1 || !address2) return false;

  return utils.getAddress(address1) === utils.getAddress(address2);
}

export function getUserFromToken(address: string): IUser | null {
  const accessToken = localStorage.getItem(LocalStorage.AccessToken);

  if (!accessToken) return null;

  const user = jwt<IUser>(accessToken);

  return user.address === address ? user : null;
}

export function setTokens({ accessToken, refreshToken }: TokensDto): void {
  localStorage.setItem(LocalStorage.AccessToken, accessToken);
  localStorage.setItem(LocalStorage.RefreshToken, refreshToken);
}

export function removeTokens(): void {
  localStorage.removeItem(LocalStorage.AccessToken);
  localStorage.removeItem(LocalStorage.RefreshToken);
}
