export enum ContractAddress {
  Factory = '0x7abC46b48a6e1becA5d29d6b0131b99c3e4366bC',
  Provider = '0xCc69c432274Ec05fbda56AF5fC0079ab6d573087',
}

export const SEPOLIA = 11155111;

export const SUPPORTED_CHAIN_IDS: number[] = [SEPOLIA];

const INSUFFICIENT_FUNDS_ERROR_CODE = -32000;
