import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { IAccount, IUser } from "./authTypes";
import {
  checkAuthentication,
  onAccountChange,
  signOut,
} from "./authThunks";

interface AuthState {
  account: IAccount | null;
  user: IUser | null;
}

const initialState: AuthState = {
  account: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccount(state, action: PayloadAction<IAccount | null>) {
      state.account = action.payload;
    },
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(onAccountChange.fulfilled, (state, action) => {
      state.account = action.payload;

      if (state.account?.address) {
        state.account.address = state.account.address.toLowerCase();
      }
    });
    builder.addCase(checkAuthentication.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addMatcher(
      isAnyOf(checkAuthentication.rejected, signOut.fulfilled),
      (state) => {
        state.user = null;
      }
    );
  },
});

export const { setAccount, setUser } = authSlice.actions;
export const authReducer = authSlice.reducer;
