import { ContextModalProps } from '@mantine/modals';
import { useAppDispatch } from '../../hooks';
import React from 'react';
import {
  CollectionType,
  ContentType,
  ImportImdbForm,
  importImdbSchema,
  useAddToWaitlistMutation,
  useFindBonusContentQuery,
} from '../../modules/collection';
import { AppButton, AppLoader } from '../../components';
import styles from './GetNotifiedModal.module.scss';
import { TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { IconMail } from '@tabler/icons-react';
import { showError, showInfo, showSuccess, showWarning } from '../../notifications';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
});

type Schema = z.infer<typeof schema>;

export const GetNotifiedModal = ({
  context,
  id,
  innerProps: { symbol },
}: ContextModalProps<{
  symbol: string;
}>) => {
  const dispatch = useAppDispatch();
  const [addToWaitlist, { isLoading }] = useAddToWaitlistMutation();
  const form = useForm<Schema>({
    validate: zodResolver(schema),
    initialValues: {
      email: '',
    },
  });

  const handleSubmit = form.onSubmit(async ({ email }) => {
    addToWaitlist({ email, symbol })
      .unwrap()
      .then(() => {
        showSuccess(`You're all set. Check your email`, `We'll notify you before it drops`);
        context.closeModal(id);
      });
  });

  return (
    <div className={styles.getNotifiedModal}>
      <form>
        <div className={styles.row}>
          <TextInput
            label='Get notified when this drops'
            placeholder='Your email'
            {...form.getInputProps('email')}
            rightSection={
              <AppButton
                disabled={isLoading}
                onClick={handleSubmit}
                className={styles.button}
                variant='white'
              >
                <IconMail style={{ marginRight: 8 }} /> RSVP
              </AppButton>
            }
          />
        </div>

        <div className={styles.fieldDescription}>
          By submitting my information, I agree to receive automated messages to the contact
          information provided
        </div>
      </form>
    </div>
  );
};
