import { FundingCollectionDto } from '../../../../modules/collection';
import {
  AppCollectors,
  AppCreatorLabel,
  AppIconButton,
  AppRteViewer,
  ContractDetails,
} from '../../../../components';
import { NavItems, NavSection } from '../../../../components/navigation';
import { IconFile2 } from '../../../../icons';
import { IconExternalLink } from '@tabler/icons-react';
import { useAppModals } from '../../../../modals';
import styles from './FundingCollectionDetails.module.scss';
import { CollectionOptions } from '../funding-collection-options/CollectionOptions';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../modules/auth';

type Props = {
  collection: FundingCollectionDto;
};

enum Section {
  About = 'about',
  Options = 'options',
  Audience = 'audience',
  Contract = 'contract',
}

export function FundingCollectionDetails({ collection }: Props) {
  const modals = useAppModals();
  const { name, address, story, user, pitchdeckId, scriptId, symbol, isWaitlist } = collection;
  const currentUser = useSelector(selectUser);

  function viewOnEtherscan() {
    const link = `https://sepolia.etherscan.io/address/${address}`;
    window.open(link, '_blank');
  }

  function openPdf(id: string) {
    modals.openPdfModal(id);
  }

  return (
    <div className={styles.collectionDetails}>
      <NavItems
        className={styles.navs}
        items={[
          { id: Section.About, label: 'About the Collection' },
          { id: Section.Options, label: 'Options & Bonuses' },
          { id: Section.Audience, label: 'Audience' },
          { id: Section.Contract, label: 'Contract Details' },
        ]}
      />

      <div className={styles.sections}>
        <NavSection
          id={Section.About}
          buttons={[
            <AppIconButton onClick={() => openPdf(scriptId)} radius='round' icon={IconFile2}>
              Read Script
            </AppIconButton>,
            <AppIconButton
              onClick={() => openPdf(pitchdeckId)}
              radius='round'
              variant='white'
              icon={IconFile2}
            >
              View Pitchdeck
            </AppIconButton>,
          ]}
          name='About the Collection'
        >
          <div className={styles.storyContainer}>
            <AppCreatorLabel {...user} className={styles.creator} />
            <AppRteViewer content={story} />
          </div>
        </NavSection>

        <NavSection
          id={Section.Options}
          name='Options & Bonuses'
          description='View all rewards and perks for collecting this release.'
        >
          <CollectionOptions
            collectionAddress={collection.address}
            options={collection.options}
            symbol={symbol}
            isUserCollection={user.id === currentUser?.id}
            isWaitlist={isWaitlist}
          />
        </NavSection>

        <NavSection
          id={Section.Audience}
          name='Audience'
          description='Claim a permanent seat in the audience by purchasing a limited edition NFT of the collection.
            Seating is determined by time of support.'
        >
          <AppCollectors collectors={collection.collectors} isLoading={false} />
        </NavSection>

        <NavSection
          id={Section.Contract}
          name='Contract Details'
          buttons={[
            <AppIconButton
              radius='round'
              icon={IconExternalLink}
              onClick={viewOnEtherscan}
              variant='outline'
              reversedOrder
              withBorder
            >
              View on Etherscan
            </AppIconButton>,
          ]}
        >
          <ContractDetails collection={collection} />
        </NavSection>
      </div>
    </div>
  );
}
