import { useFindUserTokensQuery } from '../../../../modules/token';
import { AppNoItems, AppSkeleton, AppTokenPreview } from '../../../../components';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../modules/auth';
import styles from './UserTokens.module.scss';
import { useEffect } from 'react';
import { socket } from '../../../../socket';

type Props = {
  userId: string;
};

export function UserTokens({ userId }: Props) {
  const user = useSelector(selectUser);
  const isUserToken = user && userId === user.id;
  const { data: tokens = [], isLoading, refetch } = useFindUserTokensQuery({ userId });
  const skeletons = Array.from({ length: 4 }, (_, index) => index);

  useEffect(() => {
    socket.on('tokenMinted', (userId) => {
      if (userId === user?.id) {
        refetch();
      }
    });

    return () => {
      socket.off('tokenMinted');
    };
  }, [socket]);

  return (
    <div className={styles.userTokens}>
      {isLoading ? (
        skeletons.map((place) => <AppSkeleton className={styles.item} key={place} />)
      ) : tokens.length ? (
        tokens.map((token) => (
          <AppTokenPreview className={styles.item} key={token.id} token={token} />
        ))
      ) : (
        <AppNoItems
          className={styles.noItems}
          type='token'
          title='No tokens here yet'
          description={isUserToken ? 'Collect you first token' : undefined}
        />
      )}
    </div>
  );
}
