import { useFindUserQuery } from '../../modules/user';
import { useNavigate, useParams } from 'react-router-dom';
import { AppAvatar, AppButton, AppClipboardButton, AppSkeleton } from '../../components';
import { getTruncatedHash, isCreator } from '../../utils';
import {
  IconFacebook,
  IconImdb,
  IconInstagram,
  IconSpotify,
  IconTwitter,
  IconWebsite,
} from '../../icons';
import { AppRoute } from '../../routes';
import { ProfileTabs } from './profile-tabs/ProfileTabs';
import { useSelector } from 'react-redux';
import { selectUser } from '../../modules/auth';
import { useEffect } from 'react';
import { IconComponent } from '../../modules/collection';
import { ProfileSocialMedia } from './profile-social-media/ProfileSocialMedia';
import draft from '../../images/draft.png';
import styles from './Profile.module.scss';
import classNames from 'classnames';

export function Profile() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { id = '' } = useParams<{ id: string }>();
  const { data: profile, isLoading, refetch, isError } = useFindUserQuery({ id });
  const showSkeletons = isLoading || isError;
  const isUserProfile = profile && user && user.id === profile.id;
  const isCreatorProfile = !!(profile && isCreator(profile));

  const socials: { Icon: IconComponent; link: string | null | undefined }[] = [
    { Icon: IconWebsite, link: profile?.website },
    { Icon: IconImdb, link: profile?.imdb },
    { Icon: IconTwitter, link: profile?.twitter },
    { Icon: IconInstagram, link: profile?.instagram },
    { Icon: IconFacebook, link: profile?.facebook },
    { Icon: IconSpotify, link: profile?.spotify },
  ].filter((item) => item.link);

  useEffect(() => {
    if (isError) {
      navigate(AppRoute.NotFound);
    }
  }, [isError]);

  function toProfileEdit() {
    navigate(AppRoute.UpdateProfile);
  }

  return (
    <div className={styles.profile}>
      <div className={styles.header}>
        {!!socials.length && <ProfileSocialMedia items={socials} className={styles.socials} />}
        <AppSkeleton enabled={showSkeletons} className={styles.background}>
          {profile?.background ? (
            <img alt='background' className={styles.background} src={profile.background} />
          ) : (
            <img
              alt='background'
              className={classNames(styles.background, styles.noImage)}
              src={draft}
            />
          )}
        </AppSkeleton>
        <AppSkeleton animate={false} enabled={showSkeletons} className={styles.avatar}>
          {profile && (
            <AppAvatar
              className={styles.avatar}
              avatar={profile.avatar}
              address={profile.address}
            />
          )}
        </AppSkeleton>
      </div>
      <div className={styles.info}>
        <AppSkeleton enabled={showSkeletons} className={styles.nameSkeleton}>
          {profile?.fullName && <div className={styles.name}>{profile.fullName}</div>}
        </AppSkeleton>
        <AppSkeleton enabled={showSkeletons} className={styles.usernameSkeleton}>
          <div className={styles.usernameContainer}>
            {profile?.username && <div className={styles.username}>@{profile.username}</div>}
            {profile && (
              <div className={styles.address}>
                {getTruncatedHash(profile.address)}
                <AppClipboardButton value={profile.address} withLabel={false} />
              </div>
            )}
          </div>
        </AppSkeleton>
        <AppSkeleton enabled={showSkeletons} className={styles.actionsSkeleton}>
          <div className={styles.actions}>
            {isUserProfile && (
              <AppButton
                onClick={toProfileEdit}
                className={styles.button}
                variant='white'
                radius='round'
              >
                Edit Profile
              </AppButton>
            )}
            {/*<AppIconButton*/}
            {/*  className={styles.button}*/}
            {/*  icon={IconDownload}*/}
            {/*  onClick={() => {}}*/}
            {/*  variant='outline'*/}
            {/*  withBorder*/}
            {/*>*/}
            {/*  Share*/}
            {/*</AppIconButton>*/}
          </div>
        </AppSkeleton>

        {profile?.bio && <div className={styles.bio}>{profile.bio}</div>}
      </div>

      <ProfileTabs className={styles.tabs} userId={id} isCreatorProfile={isCreatorProfile} />
    </div>
  );
}
