import { AppTabs } from '../../../components';
import { UserCollections } from './user-collections/UserCollections';
import { useEffect, useState } from 'react';
import { UserTokens } from './user-tokens/UserTokens';
import { UserShelf } from './user-shelf/UserShelf';
import { ProfileTab } from '../../../enums';
import { useLocation } from 'react-router-dom';
import { AppRoute } from '../../../routes';

type Props = {
  className?: string;
  userId: string;
  isCreatorProfile: boolean;
};

export function ProfileTabs({ className, userId, isCreatorProfile }: Props) {
  const location = useLocation();
  const initialTab = (location.state as any)?.tab;
  const [currentTab, setCurrentTab] = useState<string | null>(() => initialTab || ProfileTab.NFTs);

  useEffect(() => {
    if (isCreatorProfile && !initialTab) {
      setCurrentTab(ProfileTab.Collections);
    }
  }, [isCreatorProfile]);

  const tabs = [
    {
      tabKey: ProfileTab.NFTs,
      tabLabel: 'Owned NFTs',
      tabContent: <UserTokens userId={userId} />,
    },
    {
      tabKey: ProfileTab.Shelf,
      tabLabel: 'Film Shelf',
      tabContent: <UserShelf userId={userId} />,
    },
  ];

  if (isCreatorProfile) {
    tabs.unshift({
      tabKey: ProfileTab.Collections,
      tabLabel: 'Collections',
      tabContent: <UserCollections userId={userId} />,
    });
  }

  return (
    <AppTabs className={className} tabs={tabs} activeTab={currentTab} onTabChange={setCurrentTab} />
  );
}
