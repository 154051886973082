import {
  collectionAsyncSchema,
  useCreateFanCollectionForm,
  useCreateMutation,
} from '../../modules/collection';
import { Input, NumberInput, TextInput } from '@mantine/core';
import { AppButton, AppFormContainer, AppImageUploader, AppTabsVertical } from '../../components';
import { MB50 } from '../../constants';
import { validateAsync } from '../../utils';
import { useAppDispatch } from '../../hooks';
import { deployFanCollection } from '../../ethers';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { S3Folder } from '../../enums';
import { AppRoute } from '../../routes';
import styles from './CreateFanCollection.module.scss';
import { useAppModals } from '../../modals';

export function CreateFanCollection() {
  const modals = useAppModals();
  const form = useCreateFanCollectionForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCollection, { isLoading }] = useCreateMutation();

  const onSubmit = form.onSubmit(async (values) => {
    const result = await validateAsync(collectionAsyncSchema, values, form);

    if (result.success) {
      const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });
      dispatch(deployFanCollection(values))
        .unwrap()
        .then(async () => {
          await createCollection(values)
            .unwrap()
            .then(() => {
              navigate(`${AppRoute.Collection}/fan/${values.symbol.toLowerCase()}/form`);
            });
        })
        .finally(() => {
          modals.closeContextModal(modalId);
        });
    }
  });

  const tabs = [
    {
      tabKey: 'Smart-Contract',
      tabLabel: 'Smart Contract',
      tabContent: (
        <AppFormContainer
          className={styles.formContainer}
          formId='create-form'
          onSubmit={onSubmit}
          step={1}
          disabled={isLoading}
          title='Create Smart Contract'
          description=' To begin with, we must set the basic parameters of the smart contract before continuing
            to create the collection'
        >
          <Input.Wrapper
            className={styles.withMargin}
            label='Select media'
            {...form.getInputProps('nftImageId')}
          >
            <div className={styles.imageDescription}>This is your NFT artwork</div>

            <AppImageUploader
              className={styles.uploader}
              maxSize={MB50}
              label='At least 300x300, max 50MB, GIF, JPEG or PNG'
              onChange={(value) => form.setFieldValue('nftImageId', value[0]?.id || '')}
              onUploadStart={() => form.clearFieldError('nftImageId')}
              folder={S3Folder.Collection}
              disabled={isLoading}
              isPublic
            />
          </Input.Wrapper>

          <TextInput
            label='Collection name'
            placeholder='Enter collection name'
            {...form.getInputProps('name')}
          />
          <div className={classNames(styles.fieldDescription, styles.withMargin)}>
            Collection display name. This field cannot be updated in future so pay attention.
          </div>

          <TextInput
            label='Movie Symbol'
            placeholder='Enter movie symbol'
            {...form.getInputProps('symbol')}
          />
          <div className={classNames(styles.fieldDescription, styles.withMargin)}>
            Collection short name. This field cannot be updated in future so pay attention.
          </div>

          <div className={classNames(styles.row, styles.withMargin)}>
            <div>
              <NumberInput
                label='Number of tokens'
                placeholder='Enter number of tokens'
                hideControls
                {...form.getInputProps('maxNft')}
              />
              <div className={styles.fieldDescription}>1 - 100000</div>
            </div>
            <div>
              <NumberInput
                label='Price, ETH'
                placeholder='0.004'
                precision={10}
                hideControls
                removeTrailingZeros
                {...form.getInputProps('priceEth')}
              />
              <div className={styles.fieldDescription}>Price can be changed in future</div>
            </div>
          </div>
        </AppFormContainer>
      ),
    },
    {
      tabKey: 'Import-Data',
      tabLabel: 'Import IMDB Data',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: 'Upload-film',
      tabLabel: 'Upload Film',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: 'Create-Bonuses',
      tabLabel: 'Create Bonuses',
      tabContent: <></>,
      isDisabled: true,
    },
    {
      tabKey: 'Add Description',
      tabLabel: 'Description',
      tabContent: <></>,
      isDisabled: true,
    },
  ];

  return (
    <div className={styles.createCollection}>
      <div className={styles.header}>
        <div className={styles.title}>Create Fan Collection</div>
        <div className={styles.buttons}>
          <AppButton disabled={isLoading} onClick={() => navigate(-1)} variant='outline'>
            Cancel
          </AppButton>
          <AppButton disabled={isLoading} form='create-form' type='submit' variant='primary'>
            Deploy contract
          </AppButton>
        </div>
      </div>

      <AppTabsVertical tabs={tabs} activeTab='Smart-Contract' variant='stepper' />
    </div>
  );
}
