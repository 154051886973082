import { Manager, Socket } from 'socket.io-client';
import { API_URL } from '../api/constants';

export interface ServerToClientEvents {
  collectionDeployed: (symbol: string) => void;
  collectionTokenMinted: (symbol: string) => void;
  tokenMinted: (userId: string) => void;
  optionsSet: (symbol: string) => void;
}

export interface ClientToServerEvents {}

const manager = new Manager(API_URL, { transports: ['polling'] });
const socket: Socket<ServerToClientEvents, ClientToServerEvents> = manager.socket('/');

export { socket };
