import { ContextModalProps } from '@mantine/modals';
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import { useFindFileQuery } from '../../modules/file';
import { AppLoader } from '../../components';
import { useRef, useState } from 'react';
import styles from './PdfModal.module.scss';
import { UnstyledButton } from '@mantine/core';
import classNames from 'classnames';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

export const PdfModal = ({
  context,
  id,
  innerProps: { pdfId },
}: ContextModalProps<{
  pdfId: string;
}>) => {
  const { data, isLoading } = useFindFileQuery({ id: pdfId });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  function onDocumentLoadSuccess(pdf: { numPages: number; getPage: any }) {
    setNumPages(pdf.numPages);
    setPageNumber(1);
  }

  function gcd(a: number, b: number): number {
    return b == 0 ? a : gcd(b, a % b);
  }

  function onPageLoadSuccess({ height, width }: PDFPageProxy) {
    if (canvasRef.current && modalRef.current) {
      const r = gcd(width, height);
      const widthRatio = width / r;
      const heightRatio = height / r;
      const containerWidth = modalRef.current.clientWidth;
      canvasRef.current.style.width = `${containerWidth}px`;
      canvasRef.current.style.height = `${(containerWidth / widthRatio) * heightRatio}px`;
      setPdfLoaded(true);
    }
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div ref={modalRef} className={styles.pdfModal}>
      {!isLoading && data ? (
        <>
          {!isLoading && !pdfLoaded && <AppLoader className={styles.innerLoader} color='white' />}
          {!(pageNumber <= 1) && (
            <UnstyledButton
              className={classNames(styles.control, styles.left)}
              onClick={previousPage}
            >
              <IconChevronLeft />
            </UnstyledButton>
          )}
          {!(pageNumber >= numPages) && (
            <UnstyledButton className={classNames(styles.control, styles.right)} onClick={nextPage}>
              <IconChevronRight />
            </UnstyledButton>
          )}
          {!!numPages && (
            <div className={styles.pages}>
              {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </div>
          )}
          <div className={styles.pdfContainer}>
            <Document
              error={<></>}
              loading={<></>}
              file={data.url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                canvasRef={canvasRef}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={pageNumber}
                onLoadSuccess={onPageLoadSuccess}
              />
            </Document>
          </div>
        </>
      ) : (
        <AppLoader color='white' />
      )}
    </div>
  );
};
