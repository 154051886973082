import styles from './CollectionOptions.module.scss';
import { OptionPreviewDto } from '../../../../modules/collection';
import { OptionPreview } from './option-preview/OptionPreview';
import { useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel-react';
import { Carousel } from '@mantine/carousel';
import { IconLeft, IconRight } from '../../../../icons';
import { AppCollectionPreview, AppSkeleton } from '../../../../components';

type Props = {
  symbol: string;
  options: OptionPreviewDto[];
  collectionAddress: string;
  isUserCollection: boolean;
  isWaitlist: boolean;
};

export function CollectionOptions({
  options,
  collectionAddress,
  symbol,
  isUserCollection,
  isWaitlist,
}: Props) {
  return (
    <div className={styles.collectionOptions}>
      <div className={styles.options}>
        {options.map((option) => (
          <OptionPreview
            collectionAddress={collectionAddress}
            key={option.id}
            option={option}
            isWaitlist={isWaitlist}
            symbol={symbol}
            isUserCollection={isUserCollection}
          />
        ))}
      </div>
      <Carousel
        styles={{
          viewport: {
            overflowX: 'unset',
          },
        }}
        className={styles.carousel}
        dragFree
        breakpoints={[]}
        slideGap={20}
        withControls={false}
        slideSize={280}
        align='start'
      >
        {options.map((option) => (
          <Carousel.Slide className={styles.slide} key={option.id}>
            <OptionPreview
              collectionAddress={collectionAddress}
              key={option.id}
              option={option}
              symbol={symbol}
              isUserCollection={isUserCollection}
              isWaitlist={isWaitlist}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
}
