import { Manager } from 'socket.io-client';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ColorSchemeProvider, Global, MantineProvider } from '@mantine/core';

import { Notifications } from '@mantine/notifications';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store } from './store';
import { ModalsProvider } from '@mantine/modals';
import { modals } from './modals';
import { useThemeConfig } from './theme';
import { listenEvents, provider } from './ethers';
import { init } from './modules/config';

import './App.scss';

const { dispatch, getState } = store;

if (provider) {
  listenEvents(dispatch, getState, provider);
}

dispatch(init());

function Root() {
  const { globalStyles, theme, colorScheme, toggleColorScheme } = useThemeConfig();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MantineProvider withGlobalStyles withNormalizeCSS withCSSVariables theme={theme}>
          <Global styles={globalStyles} />
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <Notifications />
            <ModalsProvider modals={modals}>
              <App />
            </ModalsProvider>
          </ColorSchemeProvider>
        </MantineProvider>
      </BrowserRouter>
    </Provider>
  );
}

// TODO: Return   "eslintConfig": {
//     "extends": "react-app"
//   },

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<Root />);
serviceWorker.unregister();
