import classNames from 'classnames';
import { Progress } from '@mantine/core';
import { AppButton, AppCreatorLabel } from '../../../../components';
import { Collection, CollectionType } from '../../../../modules/collection';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../modules/auth';
import { IconEdit } from '../../../../icons';
import { AppRoute } from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import styles from './CollectionMinter.module.scss';
import { useAppDispatch } from '../../../../hooks';
import { mintToken } from '../../../../ethers';
import {getCompactNumber, getPercentage} from '../../../../utils';
import { useCreateTokenMutation } from '../../../../modules/token';
import { ProfileTab } from '../../../../enums';
import { useAppModals } from '../../../../modals';

type Props = {
  className: string;
  collection: Collection;
};

export function CollectionMinter({ className, collection }: Props) {
  const user = useSelector(selectUser);
  const modals = useAppModals();
  const [createToken] = useCreateTokenMutation();
  const {
    user: creator,
    nftImage,
    name,
    priceEth,
    maxNft,
    symbol,
    address,
    nftCount,
    id,
  } = collection;
  const isUserCollection = creator.id === user?.id;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const quantity = 1;

  function connect() {
    navigate(AppRoute.Connect, {
      state: { redirectRoute: `${AppRoute.Collection}/${CollectionType.Fan}/${symbol}` },
    });
  }

  function toToken(userId: string) {
    navigate(`${AppRoute.User}/${userId}`, {
      state: { tab: ProfileTab.NFTs },
    });
  }

  function collect(userAddress: string, userId: string) {
    const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });
    dispatch(mintToken({ to: userAddress, collectionAddress: address, quantity, priceEth }))
      .unwrap()
      .then(async () => {
        await createToken({ collectionId: id, quantity, priceEth });
        toToken(userId);
      })
      .finally(() => {
        modals.closeContextModal(modalId);
      });
  }

  return (
    <div className={classNames(styles.collectionMinter, className)}>
      <div className={styles.header}>
        <img className={styles.image} src={nftImage} alt='nft' />
        <div className={styles.titleBlock}>
          <div className={styles.title}>{name}</div>
          <AppCreatorLabel {...creator} isSmall isIconDisplayed={false} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.stageBlock}>
          <div className={styles.stage}>Public Sale</div>
          <div className={styles.status}>Live Now</div>
        </div>

        <div className={styles.stats}>
          <div className={styles.row}>
            <div className={styles.name}>Price</div>
            <div className={styles.value}>
              <div>{priceEth} ETH</div>
              {isUserCollection && <IconEdit className={styles.iconEdit} />}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.name}>Availability</div>
            <div className={styles.value}>OPEN EDITION</div>
          </div>

          <div className={styles.row}>
            <div className={styles.name}>Collected</div>
            <div className={styles.value}>{`${getCompactNumber(nftCount)}/${getCompactNumber(maxNft)}`}</div>
          </div>
        </div>

        <Progress
          radius='xl'
          mb={32}
          size='md'
          value={getPercentage(nftCount, maxNft)}
          styles={{
            root: {
              background: '#292A2E',
            },
          }}
        />

        {user && address ? (
          <AppButton
            className={styles.button}
            variant='white'
            radius='round'
            onClick={() => collect(user.address, user.id)}
          >
            Collect
          </AppButton>
        ) : (
          <AppButton className={styles.button} variant='white' radius='round' onClick={connect}>
            Connect wallet
          </AppButton>
        )}
      </div>
    </div>
  );
}
