import { CollectorDto } from '../../modules/user';
import { AppAvatar } from '../app-avatar/AppAvatar';
import { getCompactNumber, getTruncatedHash } from '../../utils';
import styles from './AppCollectors.module.scss';
import classNames from 'classnames';
import { AppSkeleton } from '../app-skeleton/AppSkeleton';
import isEmpty from 'is-empty-typed';
import { AppNoItems } from '../app-no-items/AppNoItems';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';

type Props = {
  collectors: CollectorDto[];
  className?: string;
  isLoading: boolean;
  type?: 'NFT' | 'Edition';
};

export function AppCollectors({ collectors, className, isLoading, type = 'NFT' }: Props) {
  const navigate = useNavigate();
  const skeletons = Array.from({ length: 25 }, (_, index) => index);

  function toUser(id: string) {
    navigate(`${AppRoute.User}/${id}`);
  }

  return (
    <div className={classNames(styles.appCollectors, className)}>
      {isLoading ? (
        skeletons.map((skeletonId) => (
          <div className={styles.cell} key={skeletonId}>
            <AppSkeleton className={styles.skeleton} />
          </div>
        ))
      ) : isEmpty(collectors) ? (
        <AppNoItems
          className={styles.noItems}
          title='Be the first one to collect'
          description='Claim a permanent seat and show your support to the author!'
          type='user'
        />
      ) : (
        collectors.slice(0, 25).map((supporter, index) => {
          const { tokenCount, username, address, avatar } = supporter;
          const name = username ? `@${username}` : getTruncatedHash(address);

          return (
            <div className={styles.cell} key={supporter.id}>
              <div onClick={() => toUser(supporter.id)} className={styles.collector}>
                <AppAvatar className={styles.avatar} address={address} avatar={avatar} />
                <div className={styles.collectorInfo}>
                  <div className={styles.place}>{index + 1}</div>
                  <div className={styles.nameContainer}>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.edition}>{`${getCompactNumber(tokenCount)} ${type}${
                      tokenCount > 1 ? 's' : ''
                    }`}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
