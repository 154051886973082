import { api } from '../../api';
import { CreateFundingTokenDto, CreateTokenDto, TokenPreviewDto } from './tokenTypes';

export const tokenApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findUserTokens: builder.query<TokenPreviewDto[], { userId: string }>({
      query: ({ userId }) => ({ url: `token/user/${userId}` }),
    }),
    createToken: builder.mutation<void, CreateTokenDto>({
      query: (form) => ({ url: 'token', method: 'POST', body: form }),
    }),
    createFundingToken: builder.mutation<void, CreateFundingTokenDto>({
      query: (form) => ({ url: 'funding-token', method: 'POST', body: form }),
    }),
  }),
});

export const { useCreateTokenMutation, useCreateFundingTokenMutation, useFindUserTokensQuery } =
  tokenApi;
