import styles from './AppCreatorLabel.module.scss';
import { Role } from '../../modules/auth';
import { getIdenticonSource, getTruncatedHash, isCreator } from '../../utils';
import { IconVerified } from '../../icons';
import classNames from 'classnames';
import { AppAvatar } from '../app-avatar/AppAvatar';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';

type Props = {
  id: string;
  username: string | null;
  address: string;
  avatar: string | null;
  className?: string;
  isSmall?: boolean;
  isIconDisplayed?: boolean;
};

export function AppCreatorLabel({
  id,
  avatar,
  address,
  username,
  className,
  isIconDisplayed = true,
  isSmall,
}: Props) {
  const navigate = useNavigate();
  const nameLabel = username ? `@${username}` : getTruncatedHash(address);
  const isVerified = true;

  function toUser(e: any) {
    e.stopPropagation();
    navigate(`${AppRoute.User}/${id}`);
  }

  return (
    <div
      onClick={toUser}
      className={classNames(styles.creatorLabel, { [styles.small]: isSmall }, className)}
    >
      {isIconDisplayed && <AppAvatar className={styles.avatar} avatar={avatar} address={address} />}
      <div className={styles.name}>{nameLabel}</div>
      {isVerified && <IconVerified className={styles.iconVerified} />}
    </div>
  );
}
