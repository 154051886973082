export * from './collectionSchema';
export * from './collectionHooks';
export * from './collectionTypes';
export * from './collectionApi';
export * from './collectionConstants';

export enum CollectionFormTab {
  SmartContract = 'SmartContract',
  ImportData = 'ImportData',
  UploadFilm = 'UploadFilm',
  Bonuses = 'Bonuses',
  Description = 'Description',
}

export enum ProfileFormTab {
  Profile = 'Profile',
  Account = 'Account',
  Wallet = 'Wallet',
}

export enum FundingCollectionFormTab {
  SmartContract = 'SmartContract',
  ScriptPitchdeck = 'ScriptPitchdeck',
  VideosPhotos = 'VideosPhotos',
  Goal = 'Goal',
  Options = 'Options',
  Story = 'Story',
}

export enum CollectionEditTab {
  Bonuses = 'Bonuses',
  Description = 'Description',
  Videos = 'Videos',
}

export enum FundingCollectionEditTab {
  ScriptPitchdeck = 'ScriptPitchdeck',
  VideosPhotos = 'VideosPhotos',
  Goal = 'Goal',
  Options = 'Options',
  Story = 'Story',
}
