import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsInitialized } from './modules/config';

import {
  BecomeCreator,
  Connect,
  Home,
  Loading,
  Main,
  NotFound,
  Profile,
  Requests,
  ConfirmEmail,
  CreateFanCollection,
  Collection,
  FundingCollectionForm,
  CollectionEdit,
  CreateCollection,
  CreateFundingCollection,
  CollectionForm,
  ProfileEdit,
  FundingCollection,
  FundingCollectionEdit,
} from './pages';
import { Private } from './components';
import { AppRoute } from './routes';
import { Role } from './modules/auth';
import { CollectionType } from './modules/collection';

export default function App() {
  const isInitialized = useSelector(selectIsInitialized);

  return isInitialized ? (
    <Routes>
      <Route path={AppRoute.Main} element={<Main />}>
        <Route path={AppRoute.Connect} element={<Connect />} />
        <Route path={AppRoute.Main} element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Fan}/:symbol`}
          element={<Collection />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol`}
          element={<FundingCollection />}
        />
        <Route
          path={AppRoute.CreateCollection}
          element={<Private Component={CreateCollection} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.CreateCollection}/${CollectionType.Fan}`}
          element={<Private Component={CreateFanCollection} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.CreateCollection}/${CollectionType.Funding}`}
          element={<Private Component={CreateFundingCollection} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Fan}/:symbol/form`}
          element={<Private Component={CollectionForm} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol/form`}
          element={<Private Component={FundingCollectionForm} role={Role.Creator} />}
        />
        <Route
          path={`${AppRoute.Collection}/${CollectionType.Fan}/:symbol/edit`}
          element={<Private Component={CollectionEdit} role={Role.Creator} />}
        />
        <Route
            path={`${AppRoute.Collection}/${CollectionType.Funding}/:symbol/edit`}
            element={<Private Component={FundingCollectionEdit} role={Role.Creator} />}
        />

        <Route path={AppRoute.Home} element={<Home />} />
        <Route path={AppRoute.UpdateProfile} element={<Private Component={ProfileEdit} />} />

        <Route path={`${AppRoute.User}/:id`} element={<Profile />} />

        <Route
          path={AppRoute.Requests}
          element={<Private Component={Requests} role={Role.Admin} />}
        />
      </Route>
      <Route
        path={AppRoute.BecomeCreator}
        element={<Private Component={BecomeCreator} restrictedRole={Role.Creator} />}
      />
      <Route
        path={AppRoute.ConfirmEmail}
        element={<Private Component={ConfirmEmail} role={Role.User} />}
      />

      <Route path={AppRoute.NotFound} element={<NotFound />} />
      <Route path={AppRoute.Wildcard} element={<NotFound />} />
    </Routes>
  ) : (
    <Loading />
  );
}
