import classNames from 'classnames';
import { Progress } from '@mantine/core';
import { AppBadge, AppButton, AppCreatorLabel } from '../../../../components';
import { CollectionType, FundingCollectionDto } from '../../../../modules/collection';
import { getImageUrlParams, resizeImage, getPercentage } from '../../../../utils';
import { IconBellRinging, IconChevronDown } from '@tabler/icons-react';
import styles from './FundingCollectionMinter.module.scss';
import { useAppModals } from '../../../../modals';

type Props = {
  className: string;
  collection: FundingCollectionDto;
};

export function FundingCollectionMinter({ className, collection }: Props) {
  const {
    user: creator,
    symbol,
    name,
    goalEth,
    waitlistCount,
    options,
    ownedBy,
    isWaitlist,
  } = collection;
  const { nftImage, tokenCount } = options[0];
  const modals = useAppModals();

  function toOptions() {
    document.getElementById('options')?.scrollIntoView();
  }

  function addToWaitlist() {
    modals.openGetNotifiedModal({ symbol });
  }

  const collectedEth = options.reduce((acc, curr) => {
    return curr.priceEth * curr.tokenCount + acc;
  }, 0);

  return (
    <div className={classNames(styles.collectionMinter, className)}>
      <div className={styles.header}>
        <AppBadge type={CollectionType.Funding} />
        <div className={styles.titleBlock}>
          <div className={styles.title}>{name}</div>
          <AppCreatorLabel {...creator} isSmall isIconDisplayed={false} />
        </div>
      </div>
      <div className={styles.content}>
        {!isWaitlist && (
          <div className={styles.valueBlock}>
            <div className={styles.collected}>{collectedEth.toPrecision(1)} ETH</div>

            <Progress
              radius='xl'
              size='md'
              value={getPercentage(collectedEth, goalEth)}
              styles={{
                root: {
                  background: '#292A2E',
                },
              }}
            />

            <div className={styles.goal}>{`Funded of ${goalEth} ETH goal`}</div>
          </div>
        )}

        <div className={styles.options}>
          <div className={styles.stat}>
            <div className={styles.statName}>Options</div>
            <div className={styles.statValue}>
              <div>{options.length}</div>
              <img src={resizeImage(nftImage, 28, 28)} alt='Nft Image' />
            </div>
          </div>

          <div className={styles.divider} />

          {isWaitlist ? (
            <div className={styles.stat}>
              <div className={styles.statName}>People In Waitlist</div>
              <div className={styles.statValue}>{waitlistCount}</div>
            </div>
          ) : (
            <div className={styles.stat}>
              <div className={styles.statName}>Owned By</div>
              <div className={styles.statValue}>{ownedBy}</div>
            </div>
          )}
        </div>

        <div className={styles.buttons}>
          {isWaitlist && (
            <AppButton
              onClick={addToWaitlist}
              className={styles.button}
              radius='round'
              variant='dark2'
            >
              Get Notified <IconBellRinging style={{ height: 20, width: 20, marginLeft: 8 }} />
            </AppButton>
          )}
          <AppButton onClick={toOptions} className={styles.button} radius='round' variant='white'>
            {isWaitlist ? 'See options' : 'Collect'} <IconChevronDown />
          </AppButton>
        </div>
      </div>
    </div>
  );
}
