import { ContextModalProps } from '@mantine/modals';
import { useAppDispatch } from '../../hooks';
import React from 'react';

import styles from './SwitchNetworkModal.module.scss';
import { AppButton } from '../../components';
import { switchNetwork } from '../../ethers';

export const SwitchNetworkModal = ({
  context,
  id,
  innerProps: { onReject, message, onSuccess },
}: ContextModalProps<{
  onSuccess: () => void;
  onReject: () => void;
  message: string;
}>) => {
  const dispatch = useAppDispatch();

  function handleClick() {
    dispatch(switchNetwork());
  }

  return (
    <div className={styles.switchNetworkModal}>
      <div className={styles.description}>
        You are using unsupported chain. Click button to switch to Sepolia network
      </div>

      <div className={styles.actions}>
        <AppButton variant='white' className={styles.button} onClick={handleClick}>
          Change to Sepolia
        </AppButton>
      </div>
    </div>
  );
};
