import { useNavigate } from 'react-router-dom';
import { CollectionTypeCard } from './collection-type-card/CollectionTypeCard';
import { AppRoute } from '../../routes';
import FunImage from '../../images/fun-image.svg';
import FanImage from '../../images/fan-image.svg';
import styles from './CreateCollection.module.scss';

export function CreateCollection() {
  const navigate = useNavigate();

  return (
    <div className={styles.createCollection}>
      <div className={styles.header}>
        <div className={styles.title}>Create Collection</div>
      </div>
      <div className={styles.options}>
        <CollectionTypeCard
          title='Fan Collection'
          image={FanImage}
          description='Start with a fan collection if you have already released your movie. You will be able to monetize your creation, delighting fans with unique bonuses and opportunities!'
          onClick={() => navigate(`${AppRoute.CreateCollection}/fan`)}
          buttonText='Create Fan Collection'
        />

        <CollectionTypeCard
          title='Funding Collection'
          image={FunImage}
          description='A vending collection will suit you if you want to raise funds for your future film! You can create options for your support, and your support can get unique content in the future!'
          onClick={() => navigate(`${AppRoute.CreateCollection}/funding`)}
          buttonText='Fund my Future Movie'
        />
      </div>
    </div>
  );
}
