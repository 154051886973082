import styles from './ContractDetails.module.scss';
import { getTruncatedHash } from '../../utils';
import dayjs from 'dayjs';

type Props = {
  collection: { createdAt: string; address: string };
};

export function ContractDetails({ collection }: Props) {
  const { createdAt, address } = collection;
  const dateStr = dayjs(createdAt).format('MMMM DD, YYYY');

  return (
    <div className={styles.contractDetails}>
      <div className={styles.row}>
        <div className={styles.name}>RELEASE DATE</div>
        <div className={styles.value}>{dateStr}</div>
      </div>

      <div className={styles.row}>
        <div className={styles.name}>FILM CONTRACT ADDRESS</div>
        <div className={styles.value}>{getTruncatedHash(address, 10)}</div>
      </div>

      <div className={styles.row}>
        <div className={styles.name}>BLOCKCHAIN</div>
        <div className={styles.value}>Ethereum</div>
      </div>

      <div className={styles.row}>
        <div className={styles.name}>TOKEN STANDARD</div>
        <div className={styles.value}>ERC-721</div>
      </div>
    </div>
  );
}
