import { AppCollectors, CollectionCarousel, TopCreators } from '../../components';
import {
  useFindAllQuery,
  useFindFeaturedQuery,
  useFindFundingCollectionsQuery,
} from '../../modules/collection';
import styles from './Home.module.scss';
import { useFindCollectorsQuery, useFindCreatorsQuery } from '../../modules/user';

export function Home() {
  const { data: fanCollections = [], isLoading: isFanLoading } = useFindAllQuery();
  const { data: fundingCollections = [], isLoading: isFundingLoading } =
    useFindFundingCollectionsQuery();
  const { data: featuredCollections = [], isLoading: isFeaturedLoading } = useFindFeaturedQuery();
  const { data: collectors = [], isLoading: areCollectorsLoading } = useFindCollectorsQuery();
  const { data: creators = [], isLoading: areCreatorsLoading } = useFindCreatorsQuery();

  return (
    <div className={styles.home}>
      <div>
        <div className={styles.title}>Featured Collections</div>
        <CollectionCarousel
          width='92%'
          height={544}
          collections={featuredCollections}
          isLoading={isFeaturedLoading}
          loop
          gap={32}
          align='center'
          controlsOffset='4%'
          type='hero'
        />
      </div>

      <div>
        <div className={styles.title}>Funding Collections</div>
        <CollectionCarousel
          width={646}
          height={430}
          collections={fundingCollections}
          isLoading={isFundingLoading}
          gap={20}
          loop
          align={0.04}
          controlsOffset='4%'
        />
      </div>

      <div>
        <div className={styles.title}>Fan Collections</div>
        <CollectionCarousel
          width={313}
          height={470}
          gap={20}
          loop
          collections={fanCollections}
          isLoading={isFanLoading}
          align={0.04}
          controlsOffset='4%'
          posterAsBackground
        />
      </div>

      <div>
        <div className={styles.title}>Filmmakers</div>
        <TopCreators
          className={styles.creators}
          creators={creators}
          isLoading={areCreatorsLoading}
        />
      </div>

      <div>
        <div className={styles.title}>Top Collectors</div>
        <AppCollectors
          isLoading={areCollectorsLoading}
          className={styles.collectors}
          collectors={collectors}
        />
      </div>
    </div>
  );
}
