import { useForm, zodResolver } from '@mantine/form';
import {
  creatorNameSchema,
  creatorBioSchema,
  creatorSocialSchema,
  updateProfileSchema,
} from './userSchema';
import { CreatorNameForm, CreatorBioForm, CreatorSocialForm, UpdateProfileForm } from './userTypes';

export const useUpdateProfileForm = () =>
  useForm<UpdateProfileForm>({
    validate: zodResolver(updateProfileSchema),
    initialValues: {
      username: '',
      email: '',
      fullName: '',
      spotify: '',
      facebook: '',
      bio: '',
      website: '',
      imdb: '',
      twitter: '',
      discord: '',
      instagram: '',
      avatarId: '',
      backgroundId: '',
    },
  });

export const useCreatorNameForm = () =>
  useForm<CreatorNameForm>({
    validate: zodResolver(creatorNameSchema),
    initialValues: {
      username: '',
      email: '',
      fullName: '',
    },
  });

export const useCreatorBioForm = () =>
  useForm<CreatorBioForm>({
    validate: zodResolver(creatorBioSchema),
    initialValues: {
      bio: '',
    },
  });

export const useCreatorSocialForm = () =>
  useForm<CreatorSocialForm>({
    validate: zodResolver(creatorSocialSchema),
    initialValues: {
      website: '',
      imdb: '',
      twitter: '',
    },
  });
