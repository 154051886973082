import { createAsyncThunk } from '@reduxjs/toolkit';
import { BigNumber, ethers } from 'ethers';
import { provider } from './ethersProvider';
import {
  factoryContract,
  getFanCollectionContract,
  getFundingCollectionContract,
} from './ethersContracts';
import { SEPOLIA } from './ethersConstants';
import { ethToWei, getBaseURI, weiToEth } from '../utils';
import {
  CreateCollectionForm,
  CreateFundingCollectionForm,
  OptionDto,
  UpdateOptionDto,
} from '../modules/collection';
import { mapOptions } from './ethersServices';

export const deployFanCollection = createAsyncThunk<void, CreateCollectionForm>(
  'ethers/deployFanCollection',
  async ({ priceEth, symbol, maxNft, name }, { rejectWithValue }) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return rejectWithValue("Can't get signer");
    }

    const factoryContractWithSigner = factoryContract.connect(signer);

    await factoryContractWithSigner.createFanCollection(
      await signer.getAddress(),
      name,
      symbol.toUpperCase(),
      getBaseURI(symbol),
      getBaseURI(symbol, true),
      maxNft,
      ethToWei(priceEth),
      true,
    );
  },
);

export const deployFundingCollection = createAsyncThunk<void, CreateFundingCollectionForm>(
  'ethers/deployFundingCollection',
  async ({ symbol, name }, { rejectWithValue }) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return rejectWithValue("Can't get signer");
    }

    const factoryContractWithSigner = factoryContract.connect(signer);

    await factoryContractWithSigner.createFundingCollection(
      await signer.getAddress(),
      name,
      symbol.toUpperCase(),
      getBaseURI(symbol),
      getBaseURI(symbol, true),
    );
  },
);

export const mintToken = createAsyncThunk<
  void,
  { to: string; quantity: number; collectionAddress: string; priceEth: number }
>(
  'ethers/mintToken',
  async ({ to, quantity, collectionAddress, priceEth }, { rejectWithValue }) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return rejectWithValue("Can't get signer");
    }

    const collectionContractWithSigner =
      getFanCollectionContract(collectionAddress).connect(signer);

    await collectionContractWithSigner.publicMint(to, quantity, {
      value: ethToWei(priceEth),
    });
  },
);

export const mintFundingToken = createAsyncThunk<
  void,
  {
    to: string;
    quantity: number;
    collectionAddress: string;
    priceEth: number;
    externalIndex: number;
  }
>(
  'ethers/mintFundingToken',
  async ({ to, quantity, collectionAddress, priceEth, externalIndex }, { rejectWithValue }) => {
    const signer = provider?.getSigner();

    if (!signer) {
      return rejectWithValue("Can't get signer");
    }

    const collectionContractWithSigner =
      getFundingCollectionContract(collectionAddress).connect(signer);

    await collectionContractWithSigner.publicMint(to, quantity, externalIndex, {
      value: ethToWei(priceEth),
    });
  },
);

export const updateOptions = createAsyncThunk<
  void,
  { collectionAddress: string; options: UpdateOptionDto[] }
>('ethers/updateOptions', async ({ collectionAddress, options }, { rejectWithValue }) => {
  const signer = provider?.getSigner();

  if (!signer) {
    return rejectWithValue("Can't get signer");
  }

  const collectionContractWithSigner =
    getFundingCollectionContract(collectionAddress).connect(signer);
  const { optionPrices, optionSupply } = mapOptions(options);
  await collectionContractWithSigner.updateOptions(optionSupply, optionPrices);
});

export const setOptions = createAsyncThunk<
  void,
  { collectionAddress: string; options: UpdateOptionDto[] }
>('ethers/setOptions', async ({ collectionAddress, options }, { rejectWithValue }) => {
  const signer = provider?.getSigner();

  if (!signer) {
    return rejectWithValue("Can't get signer");
  }

  const collectionContractWithSigner =
    getFundingCollectionContract(collectionAddress).connect(signer);
  const { optionPrices, optionSupply } = mapOptions(options);
  await collectionContractWithSigner.setOptions(optionPrices, optionSupply);
});

export const switchNetwork = createAsyncThunk<void, void>(
  'event/switchNetwork',
  async (arg, { rejectWithValue }) => {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: ethers.utils.hexValue(SEPOLIA) }],
    });
  },
);
