import { api } from "../../api";
import { NonceDto, SignInDto, SignUpForm, TokensDto } from "./authTypes";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findNonce: builder.query<NonceDto, { address: string }>({
      query: ({ address }) => `auth/nonce/${address}`,
    }),
    signUp: builder.mutation<void, SignUpForm>({
      query: (credentials) => ({
        url: "auth/sign-up",
        method: "POST",
        body: credentials,
      }),
    }),
    signIn: builder.mutation<TokensDto, SignInDto>({
      query: (signInDto) => ({
        url: "auth/sign-in",
        method: "POST",
        body: signInDto,
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: "auth/sign-out",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useSignOutMutation,
  useFindNonceQuery,
} = authApi;
