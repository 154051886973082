import { Input, NumberInput, Select, TextInput } from '@mantine/core';
import { AppFileUploader, AppFormContainer, AppImageUploader, AppTextarea } from '../../index';
import { GB2, MB50 } from '../../../constants';
import {
  ContentType,
  initialFormFile,
  OptionDto,
  useOptionForm,
} from '../../../modules/collection';
import { OptionLinks } from './option-links/OptionLinks';
import { S3Folder, FileType } from '../../../enums';
import { mapFileObj } from '../../../utils';
import classNames from 'classnames';
import styles from './OptionForm.module.scss';

type SelectOptions = ContentType.Link | ContentType.Text | ContentType.File | ContentType.None;

type Props = {
  option?: OptionDto;
  symbol: string;
  onSubmit: (bonus: Omit<OptionDto, 'id' | 'externalIndex'> & { id?: string }) => void;
};

export function OptionForm({ onSubmit, option, symbol }: Props) {
  const form = useOptionForm(option);
  const input = form.getInputProps(
    form.getInputProps('type').value === ContentType.File ? 'files' : 'content',
  );

  const handleSubmit = form.onSubmit((values) => {
    onSubmit({ ...values, _count: option?._count || { tokens: 0 } });
  });

  const selectData = [
    { value: ContentType.Text, label: 'Text' },
    { value: ContentType.Link, label: 'Links' },
    { value: ContentType.File, label: 'Files' },
    { value: ContentType.None, label: 'None' },
  ];

  return (
    <AppFormContainer formId='OptionForm' onSubmit={handleSubmit} className={styles.optionForm}>
      <Input.Wrapper
        className={styles.withMargin}
        label='Select media'
        {...form.getInputProps('nftImage')}
      >
        <div className={styles.imageDescription}>This is your NFT artwork</div>

        <AppImageUploader
          className={styles.uploader}
          maxSize={MB50}
          label='At least 300x300, max 50MB, GIF, JPEG or PNG'
          onChange={(files) =>
            form.setFieldValue('nftImage', files.length ? mapFileObj(files[0]) : initialFormFile)
          }
          onUploadStart={() => form.clearFieldError('nftImage')}
          folder={S3Folder.Collection}
          id={symbol}
          disabled={false}
          isPublic
          initialValue={option ? [option.nftImage] : undefined}
        />
      </Input.Wrapper>

      <TextInput
        className={styles.withMargin}
        label='Name'
        placeholder='E.g. "Music Playlist"'
        {...form.getInputProps('name')}
      />

      <AppTextarea
        className={styles.withMargin}
        label='Description'
        placeholder='Enter option description'
        minRows={3}
        maxRows={6}
        maxLength={500}
        {...form.getInputProps('description')}
      />

      <div className={classNames(styles.row, styles.withMargin)}>
        <div>
          <NumberInput
            label='Number of tokens'
            placeholder='Enter number of tokens'
            hideControls
            {...form.getInputProps('maxNft')}
          />
          <div className={styles.fieldDescription}>1 - 100000</div>
        </div>
        <div>
          <NumberInput
            label='Price, ETH'
            placeholder='0.004'
            precision={10}
            hideControls
            removeTrailingZeros
            {...form.getInputProps('priceEth')}
          />
          <div className={styles.fieldDescription}>Price can be changed in future</div>
        </div>
      </div>

      <Input.Wrapper className={styles.withMargin} label='Option Content' {...input}>
        <div className={styles.contentDescription}>
          Visible for token holders. Select what type of content you want to share for holders —
          files, links or text.
        </div>
        <div className={styles.selectContainer}>
          <Select
            className={styles.select}
            data={selectData}
            value={form.values.type}
            onChange={(value: SelectOptions) => {
              form.clearFieldError('content');
              form.clearFieldError('files');
              form.values.content.map((value, index) => form.clearFieldError(`content.${index}`));
              form.setFieldValue('type', value);
              form.setFieldValue('content', ['']);
              form.setFieldValue('files', []);
            }}
          />
          {
            {
              [ContentType.File]: (
                <AppFileUploader
                  maxSize={GB2}
                  onChange={(files) => form.setFieldValue('files', files.map(mapFileObj))}
                  label='Up to 3 files of any extension (up to 2Gb each)'
                  accept='*'
                  maxFiles={3}
                  multiple
                  folder={S3Folder.Collection}
                  type={FileType.File}
                  id={symbol}
                  initialValue={option?.files}
                />
              ),
              [ContentType.Link]: <OptionLinks form={form} />,
              [ContentType.Text]: (
                <AppTextarea
                  maxLength={500}
                  minRows={3}
                  maxRows={6}
                  placeholder='Enter text'
                  {...form.getInputProps(`content.${0}`)}
                />
              ),
              [ContentType.None]: (
                <div className={styles.noneDescription}>No additional content</div>
              ),
              [ContentType.Watch]: <></>,
              [ContentType.Download]: <></>,
            }[form.values.type]
          }
        </div>
      </Input.Wrapper>
    </AppFormContainer>
  );
}
