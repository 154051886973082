import { OpenContextModal } from '@mantine/modals/lib/context';

export * from './PdfModal';

export function getPdfModalContext(innerProps: { pdfId: string }): OpenContextModal<{
  pdfId: string;
}> {
  return {
    styles: {
      inner: { padding: 0, height: '100vh', width: '100vw' },
      header: { display: 'none' },
      body: { borderRadius: 12 },
      content: { borderRadius: 12 },
    },
    innerProps,
  };
}
