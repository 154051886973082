import { BigNumber, ethers } from 'ethers';
import { API_URL } from '../api/constants';
import _ from 'lodash';
import { BigNumberish } from '@ethersproject/bignumber';

export function convertEthToUsd(eth: number, rate: number): number {
  return eth * rate;
}

export function getBaseURI(symbol: string, isToken: boolean = false): string {
  return `https://api.indieline.io/metadata/${symbol}${isToken ? '/' : ''}`;
}

export function getTruncatedHash(address: string, partLength: number = 6): string {
  return `${address.substring(0, partLength)}...${address.substring(address.length - partLength)}`;
}

export async function generateAbi(abi: any): Promise<string[] | string> {
  const abiInterface = await new ethers.utils.Interface(abi);
  return abiInterface.format(ethers.utils.FormatTypes.full);
}

export function ethToWei(value: number): BigNumber {
  const decimalString = value.toFixed(18).replace(/0+$/, '');
  return ethers.utils.parseEther(decimalString);
}

export function weiToEth(wei: BigNumberish): number {
  const ethString = ethers.utils.formatEther(wei);
  return parseFloat(ethString);
}
