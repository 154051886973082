import React, { useState } from 'react';
import classNames from 'classnames';

import { AppFileUploader } from '../app-file-uploader/AppFileUploader';
import { FileObject } from '../app-file-uploader/types';
import { S3Folder, FileType } from '../../enums';
import { FileDto } from '../../modules/file';
import { AppImagePreview } from '../app-image-preview/AppImagePreview';
import styles from './AppImageUploader.module.scss';

type Props = {
  className?: string;
  previewClassName?: string;
  maxSize: number;
  onChange: (images: FileObject[]) => void;
  onError?: (message: string) => void;
  onUploadStart?: () => void;
  label: string;
  folder: S3Folder;
  id?: string;
  disabled?: boolean;
  isPublic?: boolean;
  initialValue?: FileDto[];
  withPreview?: boolean;
};

export function AppImageUploader({
  className,
  previewClassName,
  maxSize,
  label,
  onUploadStart,
  onChange,
  onError,
  folder,
  disabled,
  id,
  isPublic = false,
  initialValue,
  withPreview = true,
}: Props) {
  const [imagePreviewId, setImagePreviewId] = useState<string | undefined>();

  function handleImage(images: FileObject[]) {
    setImagePreviewId(images.length ? images[0].id : undefined);
    onChange(images);
  }

  return (
    <div className={classNames(styles.appImageUploader, className)}>
      {withPreview && (
        <AppImagePreview
          className={previewClassName}
          id={imagePreviewId}
          height={186}
          width={186}
        />
      )}

      <AppFileUploader
        className={styles.uploader}
        onUploadStart={onUploadStart}
        onError={onError}
        onChange={handleImage}
        maxSize={maxSize}
        label={label}
        disabled={disabled}
        accept='image/png, image/jpg, image/gif'
        folder={folder}
        type={FileType.Image}
        id={id}
        isPublic={isPublic}
        initialValue={initialValue}
      />
    </div>
  );
}
