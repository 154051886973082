import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '../form/Form';
import { AppButton } from '../../components';
import metamask from '../../icons/metamask.svg';
import { useAppDispatch } from '../../hooks';
import { checkAuthentication, connect, selectAccount, selectUser } from '../../modules/auth';

import styles from './ConfirmEmail.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { useConfirmEmailMutation, useFindProfileQuery } from '../../modules/user';

export function ConfirmEmail() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: profile } = useFindProfileQuery();
  const [confirmEmail, { isLoading }] = useConfirmEmailMutation();

  useEffect(() => {
    if (profile) {
      if (profile.isEmailConfirmed) {
        console.log('already confirmed');
      } else if (profile.emailConfirmationCode) {
        console.log('already sent');
      } else {
        // confirmEmail().unwrap().then();
      }
    }
  }, [profile]);

  return (
    <Form title='Email confirmation'>
      <div className={styles.description}>
        Email confirmation sent to {profile?.email}.
        <span className={styles.link} onClick={() => navigate(AppRoute.Home)}>
          Back to homepage
        </span>
      </div>
    </Form>
  );
}
