import { CollectionType, ContentType, OptionPreviewDto } from '../../../../../modules/collection';
import { getCompactNumber, resizeImage } from '../../../../../utils';
import { AppButton } from '../../../../../components';
import { mintFundingToken } from '../../../../../ethers';
import { useAppModals } from '../../../../../modals';
import { useAppDispatch } from '../../../../../hooks';
import { useCreateFundingTokenMutation } from '../../../../../modules/token';
import styles from './OptionPreview.module.scss';
import { AppRoute } from '../../../../../routes';
import { ProfileTab } from '../../../../../enums';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../modules/auth';
import { IconBellRinging } from '@tabler/icons-react';

type Props = {
  option: OptionPreviewDto;
  collectionAddress: string;
  symbol: string;
  isUserCollection: boolean;
  isWaitlist: boolean;
};

export function OptionPreview({ option, collectionAddress, symbol, isWaitlist }: Props) {
  const user = useSelector(selectUser);
  const {
    name,
    nftImage,
    description,
    priceEth,
    tokenCount,
    maxNft,
    id,
    externalIndex,
    userTokenCount,
    type,
  } = option;
  const navigate = useNavigate();
  const [createToken] = useCreateFundingTokenMutation();
  const modals = useAppModals();
  const dispatch = useAppDispatch();
  const quantity = 1;
  const hasContent = type !== ContentType.None;
  const hasAccess = hasContent && !!userTokenCount && user;

  function openContentModal() {
    modals.openContentModal({
      contentId: id,
      contentType: type,
      name: name,
      contentDescription: description,
      collectionType: CollectionType.Funding,
    });
  }

  function collect(userAddress: string, userId: string) {
    const modalId = modals.openSpinner({ text: 'Confirm transaction', withLogo: true });
    dispatch(
      mintFundingToken({
        to: userAddress,
        collectionAddress,
        quantity,
        priceEth,
        externalIndex,
      }),
    )
      .unwrap()
      .then(async () => {
        await createToken({ optionId: id, quantity, priceEth });
        toToken(userId);
      })
      .finally(() => {
        modals.closeContextModal(modalId);
      });
  }

  function toToken(userId: string) {
    navigate(`${AppRoute.User}/${userId}`, {
      state: { tab: ProfileTab.NFTs },
    });
  }

  function connect() {
    navigate(AppRoute.Connect, {
      state: { redirectRoute: `${AppRoute.Collection}/${CollectionType.Funding}/${symbol}` },
    });
  }

  return (
    <div className={styles.optionPreview}>
      <img src={resizeImage(nftImage, 300, 300)} alt='Nft Image' />
      <div className={styles.info}>
        <div className={styles.upperContainer}>
          <div className={styles.name}>{name}</div>
          <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.lowerContainer}>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div className={styles.name}>Price</div>
              <div className={styles.value}>{`${priceEth} ETH`}</div>
            </div>

            <div className={styles.stat}>
              <div className={styles.name}>Collected</div>
              <div className={styles.value}>{`${getCompactNumber(tokenCount)}/${getCompactNumber(
                maxNft,
              )}`}</div>
            </div>
          </div>
          <div className={styles.buttons}>
            {hasAccess && !isWaitlist && (
              <AppButton
                onClick={openContentModal}
                className={styles.button}
                variant='dark2'
                radius='round'
              >
                View Bonus
              </AppButton>
            )}
            {!isWaitlist && (
              <>
                {user ? (
                  <AppButton
                    onClick={() => collect(user!.address, user!.id)}
                    variant='white'
                    radius='round'
                  >{`Mint for ${priceEth} ETH`}</AppButton>
                ) : (
                  <AppButton onClick={connect} variant='white' radius='round'>
                    Connect
                  </AppButton>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
