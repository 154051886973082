import {
  CollectionType,
  useFindUserFundingCollectionsQuery,
} from '../../../../../modules/collection';
import { AppCollectionPreview, AppNoItems, AppSkeleton } from '../../../../../components';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../modules/auth';
import styles from './UserFundingCollections.module.scss';

type Props = {
  userId: string;
};

export function UserFundingCollections({ userId }: Props) {
  const user = useSelector(selectUser);
  const isUserCollections = user && userId === user.id;
  const { data: collections = [], isLoading } = useFindUserFundingCollectionsQuery({ userId });
  const skeletons = Array.from({ length: 2 }, (_, index) => index);

  return (
    <div className={styles.userFundingCollections}>
      {isLoading ? (
        skeletons.map((place) => <AppSkeleton className={styles.item} key={place} />)
      ) : collections.length ? (
        collections.map((collection) => (
          <AppCollectionPreview
            className={styles.item}
            key={collection.id}
            collection={collection}
          />
        ))
      ) : (
        <AppNoItems
          className={styles.noItems}
          type={CollectionType.Funding}
          title='No collections here yet'
          description={isUserCollections ? 'Create your first funding collection' : undefined}
        />
      )}
    </div>
  );
}
