import { AppTabs } from '../../../../components';
import { FundingCollectionDetails } from '../funding-collection-details/FundingCollectionDetails';
import { FundingCollectionDto } from '../../../../modules/collection';
import { useState } from 'react';

type Props = {
  collection: FundingCollectionDto;
};

export function FundingCollectionTabs({ collection }: Props) {
  const [currentTab, setCurrentTab] = useState<string | null>(() => 'collection-details');

  const tabs = [
    {
      tabKey: 'collection-details',
      tabLabel: 'Collection Details',
      tabContent: <FundingCollectionDetails collection={collection} />,
    },
    // {
    //   tabKey: 'activity',
    //   tabLabel: 'Activity',
    //   tabContent: <>Activity</>,
    // },
  ];

  return <AppTabs tabs={tabs} activeTab={currentTab} onTabChange={setCurrentTab} />;
}
