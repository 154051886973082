import { useForm, zodResolver } from '@mantine/form';
import {
  addDescriptionSchema,
  bonusSchema,
  fanCollectionSchema,
  fundingCollectionSchema,
  goalSchema,
  importImdbSchema,
  optionSchema,
  pitchdeckSchema,
  storySchema,
  uploadTrailersSchema,
  uploadVideosSchema,
  videosAndPhotosSchema,
} from './collectionSchema';
import {
  AddDescriptionForm,
  AddBonusForm,
  ContentType,
  CreateCollectionForm,
  Icon,
  IconBackground,
  ImportImdbForm,
  BonusDto,
  UploadVideosForm,
  UploadTrailersForm,
  CreateFundingCollectionForm,
  StoryForm,
  GoalForm,
  VideosAndPhotosForm,
  PitchdeckForm,
  OptionDto,
  OptionForm,
} from './collectionTypes';
import { initialFormFile } from './collectionConstants';

export const useCreateFanCollectionForm = () =>
  useForm<CreateCollectionForm>({
    validate: zodResolver(fanCollectionSchema),
    initialValues: {
      name: '',
      symbol: '',
      maxNft: 0,
      priceEth: 0,
      nftImageId: '',
    },
  });

export const useCreateFundingCollectionForm = () =>
  useForm<CreateFundingCollectionForm>({
    validate: zodResolver(fundingCollectionSchema),
    initialValues: {
      name: '',
      symbol: '',
    },
  });

export const useImportImdbForm = () =>
  useForm<ImportImdbForm>({
    validate: zodResolver(importImdbSchema),
    initialValues: {
      url: '',
    },
  });

export const useUploadVideosForm = () =>
  useForm<UploadVideosForm>({
    validate: zodResolver(uploadVideosSchema),
    initialValues: {
      movieId: '',
      trailerIds: [],
    },
  });

export const useTrailersForm = () =>
  useForm<UploadTrailersForm>({
    validate: zodResolver(uploadTrailersSchema),
    initialValues: {
      trailerIds: [],
    },
  });

export const useVideosAndPhotosForm = () =>
  useForm<VideosAndPhotosForm>({
    validate: zodResolver(videosAndPhotosSchema),
    initialValues: {
      pitchVideoId: '',
      coverImageId: '',
      videoIds: [],
      imageIds: [],
    },
  });

export const usePitchdeckForm = () =>
  useForm<PitchdeckForm>({
    validate: zodResolver(pitchdeckSchema),
    initialValues: {
      pitchdeckId: '',
      scriptId: '',
    },
  });

export const useDescriptionForm = () =>
  useForm<AddDescriptionForm>({
    validate: zodResolver(addDescriptionSchema),
    initialValues: {
      description: '',
    },
  });

export const useGoalForm = () =>
  useForm<GoalForm>({
    validate: zodResolver(goalSchema),
    initialValues: {
      goalEth: 0,
    },
  });

export const useStoryForm = () =>
  useForm<StoryForm>({
    validate: zodResolver(storySchema),
    initialValues: {
      story: '',
    },
  });

export const useBonusForm = (bonus?: BonusDto) =>
  useForm<AddBonusForm>({
    validate: zodResolver(bonusSchema),
    initialValues: bonus || {
      name: '',
      description: '',
      icon: Icon.Play,
      background: IconBackground.Lime,
      type: ContentType.File,
      content: [''],
      files: [],
    },
  });

export const useOptionForm = (option?: OptionDto) => {
  return useForm<OptionForm>({
    validate: zodResolver(optionSchema),
    initialValues: option
      ? { ...option }
      : {
          nftImage: initialFormFile,
          name: '',
          description: '',
          content: [''],
          files: [],
          maxNft: 0,
          priceEth: 0,
          type: ContentType.None,
          isDisabled: false,
        },
  });
};
