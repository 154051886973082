import styles from './AppBadge.module.scss';
import { CollectionType } from '../../modules/collection';
import classNames from 'classnames';

type Props = {
  type: CollectionType | 'draft' | 'soon' | 'minting';
  className?: string;
};

export function AppBadge({ type, className }: Props) {
  return (
    <div className={classNames(styles.appBadge, styles[type], className)}>
      {type === CollectionType.Fan && 'fan collection'}
      {type === CollectionType.Funding && 'funding collection'}
      {type === 'draft' && 'draft'}
      {type === 'soon' && 'soon'}
      {type === 'minting' && 'minting'}
    </div>
  );
}
