import { useGoalForm, useUpdateFundingCollectionGoalMutation } from '../../modules/collection';

import { useEffect, useState } from 'react';
import { AppFormContainer } from '../index';
import { NumberInput } from '@mantine/core';
import styles from './GoalForm.module.scss';
import { useFindEthExchangeRateQuery } from '../../modules/common';
import { convertEthToUsd } from '../../utils';

type Props = {
  id: string;
  symbol: string;
  onSubmit?: () => void;
  goalEth?: number | null;
  onChange?: (hasChanges: boolean) => void;
  step?: number;
};

export function GoalForm({ id, symbol, onSubmit, goalEth, onChange, step }: Props) {
  const { data: exchangeRate } = useFindEthExchangeRateQuery();
  const [updateGoal, { isLoading }] = useUpdateFundingCollectionGoalMutation();
  const form = useGoalForm();
  const [usdGoal, setUsdGoal] = useState(0);

  const handleSubmit = form.onSubmit(async ({ goalEth }) => {
    await updateGoal({ symbol, goalEth }).unwrap();
    onSubmit?.();
  });

  useEffect(() => {
    if (exchangeRate) {
      setUsdGoal(convertEthToUsd(form.values.goalEth, exchangeRate.USD));
    }
    onChange?.(form.values.goalEth !== goalEth);
  }, [form.values, exchangeRate]);

  useEffect(() => {
    form.setValues({ goalEth: goalEth || 0 });
  }, [goalEth]);

  return (
    <div className={styles.goalForm}>
      <AppFormContainer
        description='Without a goal, there will be no result. Determine the size of the fundraiser wisely, try not to have false hopes.'
        title='Define Goal'
        onSubmit={handleSubmit}
        formId={id}
        className={styles.form}
        step={step}
        disabled={isLoading}
      >
        <NumberInput
          label='Goal, ETH'
          placeholder='Enter collection goal'
          precision={10}
          hideControls
          removeTrailingZeros
          {...form.getInputProps('goalEth')}
        />
        <div className={styles.fieldDescription}>
          {usdGoal
            ? `~$${usdGoal}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
            : 'Collection goal can be changed in future.'}
        </div>
      </AppFormContainer>
    </div>
  );
}
