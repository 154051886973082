import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAccount, IUser, Role } from './authTypes';
import { ethereum, factoryContract, provider } from '../../ethers';
import { ContractAddress } from '../../ethers/ethersConstants';
import { utils } from 'ethers';
import { authApi } from './authApi';
import { getUserFromToken, removeTokens, setTokens } from '../../utils';
import { setUser } from './authSlice';
import { setProfile } from '../user';

export const connect = createAsyncThunk<void, void>(
  'auth/connect',
  async (arg, { dispatch, rejectWithValue, getState }) => {
    try {
      await ethereum.request({
        method: 'eth_requestAccounts',
      });
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const onAccountChange = createAsyncThunk<
  IAccount | null,
  { accounts: string[]; shouldReauth?: boolean }
>('auth/onAccountChange', async ({ accounts, shouldReauth = true }, { dispatch }) => {
  const [account] = accounts;

  if (account && provider) {
    const activeAccount: IAccount = await dispatch(getAccount(account)).unwrap();
    await dispatch(checkAuthentication({ shouldReauth }));

    return activeAccount;
  }
  return null;
});

export const checkAuthentication = createAsyncThunk<
  IUser | null,
  { shouldReauth: boolean } | undefined
>('auth/checkAuthentication', async (arg = { shouldReauth: true }, { dispatch }) => {
  if (!provider) {
    return null;
  }

  const signer = provider.getSigner();
  const address = await signer.getAddress();

  const user = getUserFromToken(address);

  if (user || !arg.shouldReauth) {
    return user;
  }

  removeTokens();
  dispatch(setUser(null));
  dispatch(setProfile(null));

  const { nonce } = await dispatch(
    authApi.endpoints.findNonce.initiate({ address }, { forceRefetch: true }),
  ).unwrap();

  const signature = await signer.signMessage(`I am signing my one-time nonce: ${nonce}`);

  const tokens = await dispatch(authApi.endpoints.signIn.initiate({ address, signature })).unwrap();

  setTokens(tokens);

  return getUserFromToken(address);
});

export const signOut = createAsyncThunk<void, void>('auth/signOut', async (arg, { dispatch }) => {
  await dispatch(authApi.endpoints.signOut.initiate()).unwrap();
  removeTokens();
  dispatch(setUser(null));
  dispatch(setProfile(null));
});

export const getAccount = createAsyncThunk<IAccount, string>('auth/getAccount', async (address) => {
  return { address: utils.getAddress(address) };
});
