import { api } from '../../api';
import {
  CompleteMultiUpload,
  CompleteMultiUploadResponse,
  GeneratePresignedUrlsParts,
  GeneratePresignedUrlsPartsResponse,
  InitiateMultipartUpload,
  InitiateMultipartUploadResponse,
} from './fileTypes';
import { Stream } from '../../types';

export const fileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    initiateMultipartUpload: builder.mutation<
      InitiateMultipartUploadResponse,
      InitiateMultipartUpload
    >({
      query: (dto) => ({
        url: `file/initiate`,
        method: 'POST',
        body: dto,
      }),
    }),
    generatePresignedUrlsParts: builder.mutation<
      GeneratePresignedUrlsPartsResponse,
      GeneratePresignedUrlsParts
    >({
      query: (dto) => ({
        url: `file/generate-urls`,
        method: 'POST',
        body: dto,
      }),
    }),
    completeMultiUpload: builder.mutation<CompleteMultiUploadResponse, CompleteMultiUpload>({
      query: (dto) => ({
        url: `file/complete`,
        method: 'POST',
        body: dto,
      }),
    }),
    findImagePreview: builder.query<{ url: string }, { id: string }>({
      query: ({ id }) => ({ url: `file/image-preview/${id}` }),
    }),
    findImage: builder.query<
      { url: string },
      { id: string; params?: { width: number; height: number } }
    >({
      query: ({ id, params }) => ({ url: `file/image/${id}`, params }),
    }),
    findFile: builder.query<{ url: string }, { id: string }>({
      query: ({ id }) => ({ url: `file/${id}` }),
    }),
  }),
});

export const {
  useLazyFindImagePreviewQuery,
  useFindFileQuery,
  useLazyFindImageQuery,
  useInitiateMultipartUploadMutation,
  useGeneratePresignedUrlsPartsMutation,
  useCompleteMultiUploadMutation,
} = fileApi;
