import { api } from '../../api';
import { EthExchangeRate } from './commonTypes';

export const commonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findEthExchangeRate: builder.query<EthExchangeRate, void>({
      query: () => ({ url: `web3/eth-exchange-rate` }),
    }),
  }),
});

export const { useFindEthExchangeRateQuery } = commonApi;
