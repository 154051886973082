import classNames from 'classnames';
import styles from './WatchBonusPreview.module.scss';
import { IconPlay, IconPlay2, IconPlay3, IconPlayerLock } from '../../../../../../icons';
import { GRADIENTS, IconBackground } from '../../../../../../modules/collection';

type Props = {
  poster?: string;
  className?: string;
  compact?: boolean;
};

export function WatchBonusPreview({ className, compact, poster }: Props) {
  return (
    <div className={classNames(styles.bonusPreview, className, { [styles.compact]: compact })}>
      <div className={styles.info}>
        <div className={styles.header}>
          <div className={styles.iconPlay} style={{ background: GRADIENTS[IconBackground.Lime] }}>
            <IconPlay />
          </div>
          <div className={styles.name}>Watch Movie</div>
        </div>
        <div className={styles.footer}>
          <div className={styles.disclaimer}>Watch Movie on Indieline.io</div>
          <div>
            You will get an exclusive opportunity to stream this movie in high definition with the
            help of Indieline handy video player.
          </div>
        </div>
      </div>
      {!compact && poster && (
        <div className={styles.preview}>
          <img src={poster} alt='poster' />
          <div
            className={styles.buttonCollect}
            style={{ background: GRADIENTS[IconBackground.Lime] }}
          >
            <IconPlayerLock />
            Watch Movie
          </div>
        </div>
      )}
    </div>
  );
}
