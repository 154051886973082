export enum Order {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Modal {
  Confirmation = 'Confirmation',
  BonusContent = 'BonusContent',
  GetNotified = 'GetNotified',
  RteLink = 'RteLink',
  Image = 'Image',
  Pdf = 'Pdf',
  RteImage = 'RteImage',
  Spinner = 'Spinner',

  //
  Dropdown = 'Dropdown',
  Account = 'Account',
  SwitchNetwork = 'SwitchNetwork',
}

export enum S3Folder {
  User = 'user',
  Collection = 'collection',
  Film = 'film',
}

export enum FileType {
  Video = 'VIDEO',
  Image = 'IMAGE',
  File = 'FILE',
}

export enum S3VideoStreamQuality {
  FullHD = '1080',
  HD = '720',
  SD = '480',
  Low = '360',
}

export enum LocalStorage {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
}

export enum ProfileTab {
  NFTs = 'owned-nfts',
  Shelf = 'film-shelf',
  Collections = 'collections',
}
