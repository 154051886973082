import { useStoryForm, useUpdateFundingCollectionStoryMutation } from '../../modules/collection';
import { useEffect, useState } from 'react';
import { AppFormContainer, AppRte, AppRteViewer } from '..';
import styles from './StoryForm.module.scss';
import { Input } from '@mantine/core';

type Props = {
  id: string;
  symbol: string;
  onSubmit?: () => void;
  story?: string | null;
  onChange?: (hasChanges: boolean) => void;
  step?: number;
};

export function StoryForm({ id, symbol, onSubmit, story, onChange, step }: Props) {
  const [updateStory, { isLoading }] = useUpdateFundingCollectionStoryMutation();
  const form = useStoryForm();

  const handleSubmit = form.onSubmit(async ({ story }) => {
    await updateStory({ symbol, story }).unwrap();
    onSubmit?.();
  });

  useEffect(() => {
    onChange?.(form.values.story !== story);
  }, [form.values]);

  useEffect(() => {
    form.setValues({ story: story || '' });
  }, [story]);

  return (
    <div className={styles.storyForm}>
      <AppFormContainer
        description='The story of the collection. Tell people why you created this collection! Tell potential contributors more about your campaign. Provide details that will motivate people to contribute. A good pitch is compelling, informative, and easy to digest'
        title={story ? 'Edit Story' : 'Add Story'}
        onSubmit={handleSubmit}
        formId={id}
        className={styles.form}
        disabled={isLoading}
        step={step}
      >
        <Input.Wrapper {...form.getInputProps('story')}>
          <AppRte
            content={story}
            onChange={(story) => {
              form.setFieldValue('story', story);
            }}
            symbol={symbol}
            maxLength={2500}
          />
        </Input.Wrapper>
      </AppFormContainer>
    </div>
  );
}
