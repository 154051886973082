import { Contract } from '@ethersproject/contracts';

import { provider } from './ethersProvider';
import { ContractAddress } from './ethersConstants';
import { fanCollectionAbi, factoryAbi, fundingCollectionAbi } from './abi';
import { IFactoryContract, IFanCollectionContract, IFundingCollectionContract } from './types';

export const factoryContract: IFactoryContract = new Contract(
  ContractAddress.Factory,
  factoryAbi,
  provider,
) as unknown as IFactoryContract;

export function getFanCollectionContract(address: string): IFanCollectionContract {
  return new Contract(address, fanCollectionAbi, provider) as unknown as IFanCollectionContract;
}

export function getFundingCollectionContract(address: string): IFundingCollectionContract {
  return new Contract(
    address,
    fundingCollectionAbi,
    provider,
  ) as unknown as IFundingCollectionContract;
}
