import { AppDispatch, RootState } from '../store';
import { onAccountChange } from '../modules/auth';
import { Network, Web3Provider } from '@ethersproject/providers';
import { networkChanged } from '../modules/config';
import { ethereum } from './ethersProvider';

export async function listenEvents(dispatch: AppDispatch, getState: () => RootState, provider: Web3Provider) {
  listenProvider(dispatch, getState, provider);

  const startBlockNumber = await provider.getBlockNumber();
  if (!startBlockNumber) return;
}

const listenProvider = (dispatch: AppDispatch, getState: () => RootState, provider: Web3Provider) => {
  provider.on('network', ({ ensAddress, chainId, name }: Network, oldNetwork: Network) => {
    dispatch(networkChanged({ ensAddress, name, chainId }));
  });
  ethereum.on('accountsChanged', (accounts: string[]) => {
    dispatch(onAccountChange({ accounts }));
  });
};
