import React, { CSSProperties, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useLazyFindImageQuery } from '../../modules/file';
import styles from './AppImagePreview.module.scss';
import { IconImage2 } from '../../icons';

type Props = {
  className?: string;
  id?: string | null;
  height: number;
  width: number;
  style?: CSSProperties;
};

export function AppImagePreview({ className, id, height, width, style }: Props) {
  const [imagePreview, setImagePreview] = useState<string>('');
  const [findImage] = useLazyFindImageQuery();

  useEffect(() => {
    if (id) {
      findImage({ id, params: { height, width } })
        .unwrap()
        .then(({ url }) => setImagePreview(url));
    } else {
      setImagePreview('');
    }
  }, [id]);

  return (
    <div style={style} className={classNames(styles.imagePreview, className)}>
      <img src={imagePreview} className={classNames(styles.preview)} alt='Image preview' />
      <IconImage2 className={styles.icon} />
    </div>
  );
}
