import { Carousel } from '@mantine/carousel';
import { useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel-react';
import { AppRoute } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { CollectionPreview } from '../../modules/collection';
import { AppCollectionPreview } from '../app-collection-preview/AppCollectionPreview';
import { Skeleton } from '@mantine/core';
import { IconLeft, IconRight } from '../../icons';
import styles from './CollectionCarousel.module.scss';
import { AppSkeleton } from '../app-skeleton/AppSkeleton';

type Props = {
  collections: CollectionPreview[];
  isLoading: boolean;
  height: number | string;
  width: number | string;
  gap: number;
  loop?: boolean;
  controlsOffset: number | string;
  align: 'start' | 'center' | 'end' | number;
  type?: 'card' | 'hero';
  posterAsBackground?: boolean;
};

export function CollectionCarousel({
  collections,
  isLoading,
  height,
  width,
  gap,
  loop,
  controlsOffset,
  align,
  type = 'card',
  posterAsBackground,
}: Props) {
  const [carouselApi, setCarouselApi] = useState<EmblaCarouselType>();
  const skeletons = Array.from({ length: 10 }, (_, index) => index);

  return (
    <Carousel
      className={styles.carousel}
      slideSize={width}
      height={height}
      slideGap={gap}
      dragFree
      breakpoints={[]}
      getEmblaApi={setCarouselApi}
      align={align}
      controlsOffset={controlsOffset}
      loop={loop}
      nextControlIcon={<IconRight />}
      previousControlIcon={<IconLeft />}
    >
      {isLoading
        ? skeletons.map((id) => (
            <Carousel.Slide key={id}>
              <AppSkeleton className={styles.skeleton} />
            </Carousel.Slide>
          ))
        : collections.map((collection) => (
            <Carousel.Slide key={collection.id}>
              <AppCollectionPreview
                posterAsBackground={posterAsBackground}
                collection={collection}
                type={type}
              />
            </Carousel.Slide>
          ))}
    </Carousel>
  );
}
