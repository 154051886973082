import styles from './AppTokenPreview.module.scss';
import { TokenPreviewDto } from '../../modules/token';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { AppBadge } from '../app-badge/AppBadge';
import { AppTokenImage } from '../app-token-image/AppTokenImage';

type Props = {
  token: TokenPreviewDto;
  className?: string;
};

export function AppTokenPreview({ token, className }: Props) {
  const {
    image,
    collectionName,
    collectionId,
    externalId,
    optionName,
    collectionSymbol,
    collectionType,
  } = token;
  const navigate = useNavigate();

  function handleClick() {
    navigate(`${AppRoute.Collection}/${collectionType}/${collectionSymbol.toLowerCase()}`);
  }

  return (
    <div onClick={handleClick} className={classNames(styles.tokenPreview, className)}>
      <AppTokenImage src={image} symbol={collectionSymbol} />
      <div className={styles.tokenInfo}>
        <div className={styles.collectionName}>
          {`${collectionSymbol}${optionName ? ' - ' + optionName : ''}`}{' '}
          {externalId && <span className={styles.id}>#{externalId}</span>}
        </div>

        {!externalId && <AppBadge className={styles.badge} type='minting' />}
      </div>
    </div>
  );
}
