import {
  AppButton,
  AppTabsVertical,
  GoalForm,
  OptionsForm,
  PitchdeckForm,
  StoryForm,
  VideosAndPhotosForm,
} from '../../components';
import {
  FundingCollectionEditTab,
  FundingCollectionFormTab,
  OptionForm,
  useFindFundingCollectionFormQuery,
} from '../../modules/collection';
import { Loading } from '../loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { selectIsLoading } from '../../api';
import { AppTab } from '../../types';
import styles from './FundingCollectionEdit.module.scss';
import { socket } from '../../socket';

export function FundingCollectionEdit() {
  const { symbol = '' } = useParams<{ symbol: string }>();
  const navigate = useNavigate();
  const { data: collectionForm, isError, refetch } = useFindFundingCollectionFormQuery({ symbol });
  const [isOptionCreationForm, toggleOptionCreationForm] = useToggle();
  const areButtonDisabled = useAppSelector(selectIsLoading);
  const [currentTab, setCurrentTab] = useState<string | null>(
    () => FundingCollectionEditTab.ScriptPitchdeck,
  );
  const formId =
    isOptionCreationForm && currentTab === FundingCollectionEditTab.Options
      ? 'OptionForm'
      : currentTab || undefined;
  const [hasFormChanges, setHasFormChanges] = useState<Record<string, boolean>>({
    [FundingCollectionEditTab.ScriptPitchdeck]: false,
    [FundingCollectionEditTab.VideosPhotos]: false,
    [FundingCollectionEditTab.Goal]: false,
    [FundingCollectionEditTab.Options]: false,
    [FundingCollectionEditTab.Story]: false,
    OptionForm: true,
  });

  useEffect(() => {
    socket.on('optionsSet', (collectionSymbol) => {
      if (symbol.toUpperCase() === collectionSymbol) {
        refetch();
      }
    });

    return () => {
      socket.off('optionsSet');
    };
  }, [socket]);

  const tabs: AppTab[] = [
    {
      tabKey: FundingCollectionEditTab.ScriptPitchdeck,
      tabLabel: 'Script & Pitchdeck',
      tabContent: (
        <PitchdeckForm
          id={FundingCollectionEditTab.ScriptPitchdeck}
          onSubmit={refetch}
          symbol={symbol.toUpperCase()}
          pitchdeck={collectionForm?.pitchdeck}
          script={collectionForm?.script}
          onChange={(hasChanges) => {
            handleOptionsChange(hasChanges, FundingCollectionEditTab.ScriptPitchdeck);
          }}
        />
      ),
    },
    {
      tabKey: FundingCollectionEditTab.VideosPhotos,
      tabLabel: 'Videos & Photos',
      tabContent: (
        <VideosAndPhotosForm
          id={FundingCollectionEditTab.VideosPhotos}
          onSubmit={refetch}
          symbol={symbol.toUpperCase()}
          videos={collectionForm?.videos}
          images={collectionForm?.images}
          pitchVideo={collectionForm?.pitchVideo}
          coverImage={collectionForm?.coverImage}
          onChange={(hasChanges) => {
            handleOptionsChange(hasChanges, FundingCollectionEditTab.VideosPhotos);
          }}
        />
      ),
    },
    {
      tabKey: FundingCollectionEditTab.Goal,
      tabLabel: 'Goal',
      tabContent: (
        <GoalForm
          id={FundingCollectionFormTab.Goal}
          symbol={symbol}
          goalEth={collectionForm?.goalEth}
          onChange={(hasChanges) => {
            handleOptionsChange(hasChanges, FundingCollectionEditTab.Goal);
          }}
          onSubmit={refetch}
        />
      ),
    },
    {
      tabKey: FundingCollectionEditTab.Options,
      tabLabel: 'Options',
      tabContent: (
        <OptionsForm
          collectionAddress={collectionForm?.address}
          isCreation={isOptionCreationForm}
          toggleOptionCreation={toggleOptionCreationForm}
          id={FundingCollectionEditTab.Options}
          symbol={symbol}
          onSubmit={refetch}
          options={collectionForm?.options || []}
          onChange={(hasChanges) => {
            handleOptionsChange(hasChanges, FundingCollectionEditTab.Options);
          }}
          areOptionsSigned={collectionForm?.areOptionsSigned || false}
          goalEth={collectionForm?.goalEth || 0}
        />
      ),
    },
    {
      tabKey: FundingCollectionEditTab.Story,
      tabLabel: 'Story',
      tabContent: (
        <StoryForm
          id={FundingCollectionFormTab.Story}
          symbol={symbol}
          story={collectionForm?.story}
          onSubmit={refetch}
          onChange={(hasChanges) => {
            handleOptionsChange(hasChanges, FundingCollectionEditTab.Story);
          }}
        />
      ),
    },
  ];

  function handleOptionsChange(hasChanges: boolean, tab: FundingCollectionEditTab) {
    setHasFormChanges((prevState) => ({
      ...prevState,
      [tab]: hasChanges,
    }));
  }

  function goBack(): void {
    navigate(-1);
  }

  return collectionForm ? (
    <div className={styles.collectionEdit}>
      <div className={styles.header}>
        <div className={styles.title}>{collectionForm?.name}</div>
        <div className={styles.buttons}>
          <AppButton
            className={styles.button}
            onClick={isOptionCreationForm ? toggleOptionCreationForm : goBack}
            variant='outline'
            disabled={areButtonDisabled}
          >
            Cancel
          </AppButton>
          <AppButton
            className={styles.button}
            disabled={areButtonDisabled || (!!formId && !hasFormChanges[formId])}
            form={formId}
            type='submit'
            variant='primary'
          >
            Save
          </AppButton>
        </div>
      </div>

      <AppTabsVertical activeTab={currentTab} onTabChange={setCurrentTab} tabs={tabs} />
    </div>
  ) : (
    <Loading />
  );
}
