export enum AppRoute {
  Main = '/',
  Home = '/home',
  Collection = '/collection',
  CreateCollection = '/create-collection',
  NotFound = '/not-found',
  User = '/user',
  UpdateProfile = '/update-profile',
  Wildcard = '/*',

  BecomeCreator = '/become-creator',
  Requests = '/requests',
  Connect = '/connect',
  ConfirmEmail = '/confirm-email',
}
