import React, { useEffect } from 'react';
import { Outlet, useLocation, matchRoutes, matchPath } from 'react-router-dom';
import { Header } from '../../components';
import styles from './Main.module.scss';
import { AppRoute } from '../../routes';
import { CollectionType } from '../../modules/collection';
import { selectAvailability, ServiceAvailability } from '../../modules/config';
import { useSelector } from 'react-redux';
import { useAppModals } from '../../modals';
import { closeModal } from '@mantine/modals';
import { Modal } from '../../enums';

export function Main() {
  const modals = useAppModals();
  const { pathname } = useLocation();
  const availability = useSelector(selectAvailability);
  const routesWithoutBorder = [
    `${AppRoute.Collection}/${CollectionType.Fan}/:symbol`,
    `${AppRoute.Collection}/${CollectionType.Funding}/:symbol`,
    AppRoute.Home,
  ];

  useEffect(() => {
    if (availability === ServiceAvailability.UnsupportedNetwork) {
      modals.switchNetwork();
    }

    if (availability === ServiceAvailability.Available) {
      modals.closeContextModal(Modal.SwitchNetwork);
    }
  }, [availability]);

  const isBorderHidden = routesWithoutBorder.some((route) => matchPath(route, pathname));

  return (
    <div className={styles.container}>
      <Header className={styles.header} withBorder={!isBorderHidden} />
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>
  );
}
