import { Network } from '@ethersproject/networks';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServiceAvailability } from './configServices';
import { ServiceAvailability } from './configConstants';
import { provider } from '../../ethers';
import { onAccountChange } from '../auth';

export const init = createAsyncThunk<void, void>('config/init', async (arg, { dispatch }) => {
  if (provider) {
    const { chainId, name, ensAddress } = await provider.detectNetwork();
    const accounts = await provider.listAccounts();

    await dispatch(networkChanged({ chainId, name, ensAddress }));
    await dispatch(onAccountChange({ accounts, shouldReauth: false }));
  }
});

export const networkChanged = createAsyncThunk<
  { availability: ServiceAvailability; network: Network },
  Network
>('config/networkChanged', async (network, { getState, dispatch, rejectWithValue }) => {
  const availability = getServiceAvailability(network);

  return { availability, network };
});
