export function isString(value: any): value is string {
  return typeof value === "string" || value instanceof String;
}

export function findAllByKey(obj: any, keyToFind: string): any[] {
  if (!obj) return [];

  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      key === keyToFind
        ? acc.concat(value)
        : typeof value === "object"
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc,
    [] as any
  );
}

export function expand(obj: any) {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i],
      subkeys = key.split(/,\s?/),
      target = obj[key];
    delete obj[key];
    subkeys.forEach((key) => {
      obj[key] = target;
    });
  }
  return obj;
}
