import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { checkAuthentication, connect, selectAccount, selectUser } from '../../modules/auth';
import image from '../../images/connect-logo.png';
import styles from './Connect.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { IconCoinbase, IconMetamask, IconWalletConnect } from '../../icons';
import { AppBadge } from '../../components';

export function Connect() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const account = useSelector(selectAccount);
  const signedUser = useSelector(selectUser);
  const [error, setError] = useState('');
  const [connection, setConnection] = useState(false);

  function redirect() {
    const route = (location.state as any)?.redirectRoute || AppRoute.Home;
    navigate(route, { replace: true });
  }

  useEffect(() => {
    if (account && signedUser) {
    }
  }, [account, signedUser, connection]);

  function connectMetamask() {
    setError('');
    setConnection(true);

    if (account) {
      dispatch(checkAuthentication())
        .unwrap()
        .then(redirect)
        .catch(setErrorMessage)
        .finally(() => setConnection(false));
    } else {
      dispatch(connect())
        .unwrap()
        .then(redirect)
        .catch(setErrorMessage)
        .finally(() => setConnection(false));
    }
  }

  function setErrorMessage(e: Error & { code?: string }) {
    let { message = 'Something went wrong' } = e;
    if (e.code === 'ACTION_REJECTED') message = 'User rejected signing';
    setError(message);
  }

  return (
    <div className={styles.connect}>
      <img src={image} alt='Logo image' />

      <div className={styles.wallets}>
        <div className={styles.workingWallets}>
          <div className={styles.title}>Connect wallet</div>
          <div className={styles.description}>
            For now we only support Metamask wallet. We’ll add more wallet vendors in future.
          </div>
          <div onClick={connectMetamask} className={styles.wallet}>
            <IconMetamask className={styles.icon} />
            <div className={styles.name}>Metamask</div>
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </div>
        <div className={styles.upcomingWallets}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Sign Up with Email</div>
            <AppBadge type='soon' />
          </div>
          <div className={styles.description}>
            Choose how you want to connect. There are several wallet providers.
          </div>
          <div className={styles.wallet}>
            <IconCoinbase className={styles.icon} />
            <div className={styles.name}>Coinbase Wallet</div>
            <AppBadge type='soon' />
          </div>
          <div className={styles.wallet}>
            <IconWalletConnect className={styles.icon} />
            <div className={styles.name}>Wallet Connect</div>
            <AppBadge type='soon' />
          </div>
        </div>
      </div>
    </div>
  );
}
