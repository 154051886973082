import { Handler } from "./types";
import { isRejected } from "@reduxjs/toolkit";
import {
  showErrorBody,
  showError,
  showSuccess,
} from "../../notifications";
import { checkAuthentication } from "../../modules/auth";
import { findProfile, setProfile } from "../../modules/user";

export const handlers: Handler[] = [
  {
    matcher: isRejected,
    effect: async (action) => {
      console.log("Rejected", action);
    },
  },
  {
    actionCreator: checkAuthentication.fulfilled,
    effect: async (action, { dispatch }) => {
      dispatch(action.payload ? findProfile() : setProfile(null));
    },
  },
];
